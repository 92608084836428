import * as MicroModal from "micromodal";

export default function ModalVideo() {
  const modalVideos = document.querySelectorAll("[data-video-modal]");

  if (modalVideos) {
    modalVideos.forEach((iFrame: HTMLIFrameElement) => {
      const iframeEl = iFrame;
      const iFrameSrc = iFrame.src;

      MicroModal.default.init({
        onClose: () => {
          if (!iframeEl.hasAttribute('data-trackable')) {
            iframeEl.src = '';
          }
        },
        onShow: () => {
          if (!iframeEl.hasAttribute('data-trackable')) {
            iframeEl.src = iFrameSrc;
          }
        },
        disableScroll: true,
        awaitCloseAnimation: true,
      });
    });
  }
}

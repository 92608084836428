export default function onInit() {
  let gatedContent = document.querySelector('.GatedContent--general');
  const modalExit = document.querySelector('.ModalExit');

  function initGatedContentModal(url?: string, title?: string) {
    if (!gatedContent) {
      gatedContent = document.querySelector('.GatedContent');
    }

    if (modalExit) {
      modalExit.addEventListener('click', () => {
        exitButtonKickback(url);
      });
    
      gatedContent.classList.remove('hidden');
    } else {
      exitButtonKickback(url, true);
    }

    if (title) {
      (window as any).dataLayer.push({
        event: 'accessContent',
        pdfFileName: title,
      });
    }

    window.addEventListener('message', (event) => {
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmitted'
      ) {
        setCookie('subscribed', true, 183);

        if (title) {
          (window as any).dataLayer.push({
            event: 'accessContentFormSubmission',
            pdfFileName: title,
          });
        }

        modalExit.removeEventListener('click', () => {
          exitButtonKickback(url, true);
        });
        modalExit.addEventListener('click', () => {
          exitButtonHidden();
        });
        initExitCTA();
      }
    });
  }

  function exitButtonHidden() {
    gatedContent.classList.add('hidden');
  }

  function exitButtonKickback(url?: string, submitted: boolean = false) {
    if (url && submitted) {
      window.open(url, '_blank');
    } else if (!submitted) {
      exitButtonHidden();
    } else if (document.referrer) {
      window.location.href = document.referrer;
    } else {
      window.location.href = window.location.origin;
    }
  }

  function initExitCTA() {
    const exitCTA = document.createElement('a');
    exitCTA.innerHTML = '<span>Back to Site →</span>';
    exitCTA.classList.add('btn', 'btn-primary', 'mt-8', 'cursor-pointer');
    exitCTA.addEventListener('click', () => {
      exitButtonHidden();
    });
    document.querySelector('.GatedContent .HubspotForm').appendChild(exitCTA);
    document.querySelector('.gatedHeadingBlock').remove();
    document.querySelector('.gatedInstructionBlock').remove();
  }

  function setCookie(cname: string, cvalue: boolean, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  function getCookie(cname: string) {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  function checkCookie(url?: string, title?: string) {
    let subscribed = getCookie('subscribed');
    if (subscribed == 'true') {
      modalExit.addEventListener('click', () => {
        exitButtonHidden();
      });
      if (url) {
        window.open(url, '_blank');
      }
    } else {
      initGatedContentModal(url, title);
    }
  }

  function clickResource() {
    let resources = document.querySelectorAll("[data-gated='1']");
    resources.forEach((resource: HTMLElement) => {
      resource.addEventListener('click', (event) => {
        let documentTitle = resource.getAttribute('title');
        event.preventDefault();
        // (window as any).dataLayer.push({
        //   event: 'contentdownload',
        //   pdfFileName: documentTitle,
        // });
        checkCookie(resource.getAttribute('href'), documentTitle);
      });
    });
  }

  if (gatedContent) {
    checkCookie();
  }

  clickResource();
}

import * as Flickity from "flickity-as-nav-for";

const updateYearCells = (
  yearCells: any,
  copyCells: any,
  carousels: any,
  selectedIndex: any
) => {
  if (yearCells && copyCells) {
    // Remove active styling from all years
    yearCells.forEach((cell: any) => {
      cell.classList.remove(
        "text-red-600",
        "text-6xl",
        "lg:w-1/4",
        "lg:mr-8",
        "lg:w-1/5",
        "lg:mr-6",
        "lg:ml-2",
        "lg:text-center"
      );
      cell.classList.add("text-gray-500");
    });

    carousels.forEach((cell: any) => {
      cell.parentElement.classList.add("invisible", "h-0");
    });

    const activeCarousel = carousels[selectedIndex];
    activeCarousel.parentElement.classList.remove("invisible", "h-0");

    copyCells.forEach((cell: any) => {
      cell.classList.add("hidden");
    });

    // Add active styling to the selected year
    const activeYear = yearCells[selectedIndex];
    const activeCopy = copyCells[selectedIndex];

    if (activeYear) {
      if (selectedIndex === 0) {
        activeYear.classList.add("lg:w-1/4", "lg:mr-8");
      } else if (selectedIndex === 1) {
        activeYear.classList.add(
          "lg:w-1/5",
          "lg:mr-6",
          "lg:ml-2",
          "lg:text-center"
        );
      }
      activeYear.classList.add(
        "text-red-600",
        "text-6xl",
        selectedIndex === 0 ? "pr-4" : "px-4"
      );
      activeYear.classList.remove("text-gray-500");
    }

    if (activeCopy) {
      activeCopy.classList.remove("hidden");
    }
  }
};

export default function onInit() {
  const carousels = document.querySelectorAll(".timeline-carousel");
  const buttonGroup = document.querySelector(".carousel-year-navigation");
  const yearCells = buttonGroup?.querySelectorAll<HTMLSpanElement>(
    ".carousel-year"
  );
  const copyCells = document.querySelectorAll<HTMLSpanElement>(
    ".carousel-timeline-copy"
  );

  if (buttonGroup) {
    buttonGroup.addEventListener("click", function (event: any) {
      const selectedIndex = Number(event.target.getAttribute("data-index"));
      updateYearCells(yearCells, copyCells, carousels, selectedIndex);
    });
  }

  if (carousels) {
    carousels.forEach((carousel, key) => {
      const flkty = new Flickity(`.timeline-carousel-${key}`, {
        wrapAround: true,
        prevNextButtons: false, // Disable default buttons
        pageDots: false,
      });

      // Custom Previous Button
      const prevButton = document.getElementById(`prevButton-${key}`);
      if (prevButton) {
        prevButton.addEventListener("click", () => {
          flkty.previous(); // Go to the previous slide
        });
      }

      // Custom Next Button
      const nextButton = document.getElementById(`nextButton-${key}`);
      if (nextButton) {
        nextButton.addEventListener("click", () => {
          flkty.next(); // Go to the next slide
        });
      }
    });
  }
}

export function manageIframe() {
  if (window.location.href.includes('kioskmode=true')) {
    document.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
      let anchor = a;

      anchor.addEventListener('click', function (event) {
        event.preventDefault();

        checkLink(anchor);
      });
    });

    let editor = document.getElementsByClassName("Wysiwyg");
    if (editor.length > 0) {
      editor[0].querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
        let link = a;
  
        link.addEventListener('click', function (event) {
          event.preventDefault();
  
          checkLink(link);
        });
      });
    }
  }
}

function checkLink(link: any) {
  if (link.href.indexOf('.pdf') !== -1 || link.href.indexOf('widen') !== -1) {
    window.top.postMessage(link.href, '*');
  } else if (
    link.hostname !== '' &&
    !link.hostname.includes('goldbondbuilding') &&
    !link.hostname.includes('nationalgypsum') &&
    !link.hostname.includes('askforpurple') &&
    !link.hostname.includes('permabase') &&
    !link.hostname.includes('proformfinishing')
  ) {
    window.top.postMessage('showErrorModal', '*');
  } else if (link.href.indexOf('tel') !== -1 || link.href.indexOf('mailto') !== -1) {
    link.classList.add('btn-disable');
  } else {
    window.location.href = `${link.href}?kioskmode=true`;
  }
}

window.onmessage = function (e: any) {
  if (e.data == 'scrollTop') {
    window.scrollTo(0, 0);
  }
};

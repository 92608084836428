function createForms() {
  if (window.hbspt) {
    const selectors:NodeListOf<HTMLElement> = document.querySelectorAll(
      '[data-hubspot-form-id]'
    );
    const firstElem:HTMLElement = selectors[0];
    const { hubspotPortalId } = firstElem.dataset;

    selectors.forEach((element: HTMLDivElement) => {
      const { hubspotFormId } = element.dataset;
      const options = {
        portalId: hubspotPortalId,
        formId: hubspotFormId,
        target: `#${element.id}`,
      };
      console.log(options);
      window.hbspt.forms.create(options);
    });
  } else {
    setTimeout(createForms, 1);
  }
}

function loadHubspot() {
  // inspired by react-hubspot-form https://github.com/escaladesports/react-hubspot-form/blob/master/src/index.js
  const script = document.createElement('script');
  script.defer = true;
  script.onload = () => {
    createForms();
  };
  script.src = '//js.hsforms.net/forms/v2.js';
  document.head.appendChild(script);
}

export default function onInit() {
  const selectors:NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-hubspot-form-id]'
  );
  if (!selectors.length) {
    return;
  }
  loadHubspot();
}

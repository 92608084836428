export const templateSearchCard = `
  <a href="%url%" class="Search-results-card">
    <img src="%image%" alt="%title%" data-search-card-image class="Search-results-card-image">
    <div class="Search-results-card-copy">
      <div>
        <p class="font-secondary text-eyebrow font-semibold uppercase">%eyebrow%</p>
        <h6>%title%
        <svg width="24"
          height="24"
          class="text-primary-base"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M5.19502 13.305C5.32002 13.43 5.49003 13.5006 5.66689 13.5006C5.84376 13.5006 6.01315 13.43 6.13814 13.305L10.9713 8.47184H10.9719C11.0969 8.34684 11.1675 8.17684 11.1675 7.99997C11.1675 7.82311 11.0969 7.65309 10.9719 7.5281L6.13877 2.69498H6.13814C5.87814 2.43436 5.45565 2.43436 5.19502 2.69498C4.9344 2.9556 4.9344 3.3781 5.19502 3.6381L9.55694 8.00002L5.19502 12.3619C5.07002 12.4869 4.99939 12.6563 4.99939 12.8332C4.99939 13.0101 5.07002 13.1801 5.19502 13.3051V13.305Z"
            fill="currentColor" />
        </svg>
      </div>
      <p>%desc%</p>
    </div>
  </a>
`;

export const templatePageCard = `
  <a href="%url%" class="Search-results-card">
    <img src="%image%" alt="%title%" data-search-card-image class="Search-results-card-image">
    <div class="Search-results-card-copy">
      <div>
        <p class="font-secondary text-eyebrow font-semibold uppercase">%eyebrow%</p>
        <h6>%title%</h6>
        <i class="Button-arrow"></i>
      </div>
      <p>%desc%</p>
    </div>
    <button class="btn btn-default font-bold text-xl leading-normal ml-4"><span>View %resultType%</span></button>
  </a>
`;

export const templateSearchResult = '<a href="%url%" class="Button Button-link Search-results-button">%site% %title%</a>';

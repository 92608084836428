import initAccordion from './modules/accordion';
import initCalculator from './modules/calculator';
import initCarousel from './modules/carousel';
import initTimeline from './modules/timeline';
import initFloatingPlaceholder from './modules/floating-placeholder';
import initFsaDetail from './modules/fsa-detail';
import initGatedContent from './modules/gated-content';
import initGAEvents from './modules/ga4-events';
import initHeader from './modules/header';
import initMap from './modules/map';
import initWhoWeAreMap from './modules/map-who-we-are';
import initMobileNav from './modules/mobile-nav';
import initModalVideo from './modules/modal-video';
import initSmoothScroll from './modules/smooth-scroll';
import initSubmittalBuilder from './modules/submittal-builder';
import initSearch from './modules/search';
import initSelect from './modules/select';
import initInternationalDistributors from './modules/international-distributors';
import initSocialShare from './modules/social-share';
import initTabs from './modules/tabs';
import initHubspotForms from './modules/hubspot-form';
import initExpertConnection from './modules/expert-connection';
import { manageIframe } from './modules/kiosk';
import { externalLinks } from './utils';
import initPolyfills from './utils/polyfills';
import initDesignResourceCenter from './modules/design-resource-center';

const App = (): void => {
  initPolyfills();

  // Load modules here
  initAccordion();
  initCalculator();
  initCarousel();
  initTimeline();
  initFloatingPlaceholder();
  initGatedContent();
  initHeader();
  initHubspotForms();
  initFsaDetail();
  initMap();
  initGAEvents();
  initWhoWeAreMap();
  initMobileNav();
  initModalVideo();
  initTabs();
  initSearch();
  initSelect();
  initInternationalDistributors();
  initSmoothScroll();
  initSocialShare();
  initSubmittalBuilder();
  initExpertConnection();

  externalLinks();
  manageIframe();
  initDesignResourceCenter();
};

document.addEventListener('DOMContentLoaded', () => {
  App();
});

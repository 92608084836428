export default function onInit() {
  const { body } = document;
  const mobileMenu = document.querySelector('[data-mobilemenu]');
  const mobileMenuTrigger = document.querySelector('[data-mobilemenu-trigger]');
  const mobileSubmenus = document.querySelectorAll('[data-submenu]');
  const submenuTriggers = document.querySelectorAll('[data-submenu-trigger]');
  const mobileMenuQuickLinks = document.querySelector('[data-mobilemenu-quicklinks]');
  const mobileMenuCountriesLinks = document.querySelector('[data-mobilemenu-countrieslinks]');
  const mobileMenuQuickLinksTrigger = document.querySelector('[data-mobilemenu-quicklinks-trigger]');
  const mobileMenuCountriesLinksTrigger = document.querySelector('[data-mobilemenu-countrieslinks-trigger]');

  mobileMenuTrigger.addEventListener('click', () => {
    mobileMenuTrigger.classList.toggle('is-active');
    document.body.classList.toggle('is-mobileMenuOpen');
    mobileMenu.classList.toggle('is-open');

    if (body.classList.contains('is-submenuOpen')) {
      body.classList.remove('is-submenuOpen');
    }
  });

  if (mobileMenuQuickLinksTrigger) {
    mobileMenuQuickLinksTrigger.addEventListener('click', () => {
      mobileMenuQuickLinksTrigger.classList.toggle('is-active');
      document.body.classList.toggle('is-mobileMenuQuickLinksOpen');
      mobileMenuQuickLinks.classList.toggle('is-open');
  
      if (body.classList.contains('is-submenuOpen')) {
        body.classList.remove('is-submenuOpen');
      }
    });
  }

  if (mobileMenuCountriesLinksTrigger) {
    mobileMenuCountriesLinksTrigger.addEventListener('click', () => {
      mobileMenuCountriesLinksTrigger.classList.toggle('is-active');
      document.body.classList.toggle('is-mobileMenuCountriesLinksOpen');
      mobileMenuCountriesLinks.classList.toggle('is-open');
  
      if (body.classList.contains('is-submenuOpen')) {
        body.classList.remove('is-submenuOpen');
      }
    });
  }

  function onClick(e: any) {
    const submenuName = e.target.dataset.submenuTrigger;

    mobileSubmenus.forEach((submenu) => {
      submenu.classList.remove('is-open');
    });

    if (submenuName) {
      const submenu = document.querySelector(`[data-submenu="${submenuName}"]`);

      submenu.classList.add('is-open');
      body.classList.add('is-submenuOpen');
    } else {
      const openSubmenu = document.querySelector('[data-submenu].is-open');

      if (openSubmenu) {
        openSubmenu.classList.remove('is-open');
      }

      body.classList.remove('is-submenuOpen');
    }
  }

  submenuTriggers.forEach((trigger) => {
    trigger.addEventListener('click', onClick);
  });
}

import * as qs from 'qs';
import { createBasicParams } from '../modules/ga4-events';

export function bind(
  node: HTMLElement,
  action: string,
  method: (e: Event) => void
): void {
  node.addEventListener(action, method);
}

export function unbind(
  node: HTMLElement,
  action: string,
  method: (e: Event) => void
): void {
  node.removeEventListener(action, method);
}

export function getCurrentQueryString() {
  if (window.location.search) {
    return qs.parse(window.location.search.replace('?', ''));
  }
  return {};
}

export function pushToQuery(e: Event) {
  const node = e.currentTarget as HTMLInputElement | HTMLSelectElement;
  const key = node.getAttribute('name');
  const { value } = node;
  const queryString = getCurrentQueryString();
  queryString[key] = value;

  window.history.replaceState(
    null,
    null,
    `${window.location.pathname}?${qs.stringify(queryString)}`
  );
}

export function externalLinks() {
  document.querySelectorAll('a').forEach((a: HTMLAnchorElement) => {
    const anchor = a;
    const localBrand =
      (a.hostname !== '' && a.hostname.includes('goldbondbuilding')) ||
      a.hostname.includes('askforpurple') ||
      a.hostname.includes('permabase') ||
      a.hostname.includes('proformfinishing') ||
      a.hostname.includes('unifix');

    if (anchor.dataset.local === undefined) {
      if (window.location.hostname !== a.hostname && a.hostname !== '') {
        anchor.rel = 'noopener noreferrer';
        anchor.setAttribute('target', '_blank');
      }
    }

    a.addEventListener('click', () => {
      if (localBrand) {
        let brandSelected = 'Gold Bond Building';

        if (a.hostname.includes('askforpurple')) {
          brandSelected = 'Ask For Purple';
        } else if (a.hostname.includes('permabase')) {
          brandSelected = 'Permabase';
        } else if (a.hostname.includes('proformfinishing')) {
          brandSelected = 'Proform Finishing';
        } else if (a.hostname.includes('unifix')) {
          brandSelected = 'Unifix';
        }

        (window as any).dataLayer.push({
          event: 'brandClicks', //populate the event exactly as written
          brandSelected: brandSelected, //populate with the brand selected
          ...createBasicParams(),
        });
      }
    });
  });
}

export function camelToTitle(text: string) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

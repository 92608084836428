import MeiliSearch from 'meilisearch';
import { debounce } from 'throttle-debounce';
// import { createBasicParams } from '../components/entry-filter';
import { externalLinks } from '../utils';
import { createBasicParams } from './ga4-events';
import {
  templatePageCard,
  templateSearchCard,
  templateSearchResult,
} from './search-templates';

const url: URL = new URL(document.location.href);
const params: URLSearchParams = url.searchParams;
const group: string = params.get('group');
const query: string = params.get('q');

const siteHandle: string = document.body.dataset.site;

const elSearch: HTMLElement = document.querySelector('[data-search]');
const elSearchQuery: HTMLSpanElement = document.querySelector(
  '[data-search-query]'
);
const elSearchCount: HTMLSpanElement = document.querySelector(
  '[data-search-count]'
);
const elSearchOverlay: HTMLElement = document.querySelector(
  '[data-search-overlay]'
);
const elSearchInputs: NodeListOf<HTMLInputElement> = document.querySelectorAll(
  '[data-search-input]'
);
const elSearchClear: HTMLButtonElement = document.querySelector(
  '[data-search-clear]'
);
const elMoreResultsButtons: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-more]'
);
const elSearchTriggers: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-trigger]'
);
const elAllTabs: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-tab]'
);
const elAllSearchContent: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-tab-content]'
);
const elSearchResultsProducts: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-results="products"]'
);
const elSearchResultsResources: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-results="resources"]'
);
const elSearchResultsNewsrooms: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-results="newsrooms"]'
);
const elSearchResultsProjectProfiles: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-results="projectProfiles"]'
);
const elSearchResultsOther: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-search-results="other"]'
);

let hitCount: number = 0;

const config: { host: string; apiKey: string } = JSON.parse(
  elSearch.getAttribute('data-search')
);
const client = new MeiliSearch(config);

const productIndex = client.getIndex(`${siteHandle}_products`);
const resourceIndex = client.getIndex(`${siteHandle}_resources`);
// hard coding this to the ngc because its the only one that has a newsroom
const newsroomIndex = client.getIndex('ngc_newsrooms');
// const projectProfiles = client.getIndex(`${siteHandle}_projectProfiles`);

function createHTML(template: string, data: any) {
  const dom = document.createElement('div');
  dom.innerHTML = template;
  const replacedTags = dom.innerHTML.replace(/%(\w*)%/g, (m, key) => {
    return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : '';
  });
  return replacedTags;
}

const updateHTML = (
  data: any,
  type: string,
  resultType: string = 'Product'
) => {
  // Generate card HTML and inject into the hits div
  let hitHTML;
  const title: string = data.title || '';
  const eyebrow: string = `${data.eyebrow || data.site || ''} ${
    data.productType
      ? data.productType.map((productType: string) => `&bull; ${productType}`)
      : ''
  }`;
  const desc: string = data.productShortDescription || '';
  const image: string = data.image || undefined;
  const hitUrl: string = data.getUrl || data.getUrl || data.url || '';
  const site = data.site ? `${data.site} | ` : '';

  if (type === 'card') {
    hitHTML = createHTML(templateSearchCard, {
      title,
      eyebrow,
      desc,
      image,
      url: hitUrl,
      resultType,
    });
  } else if (type === 'page-card') {
    hitHTML = createHTML(templatePageCard, {
      title,
      eyebrow,
      desc,
      image,
      url: hitUrl,
      resultType,
    });
  } else {
    hitHTML = createHTML(templateSearchResult, {
      title,
      site,
      url: hitUrl,
    });
  }

  // if there is no image, then nuke the img tag to prevent broken images
  const vDom = document.createElement('div');
  vDom.innerHTML = hitHTML;
  const img = vDom.querySelector('img');
  if (img && img.src.indexOf('undefined') > -1) {
    img.remove();
  }

  return vDom.innerHTML;
};

// function removeClass() {
//   elAllTabs.forEach(tab => {
//     tab.classList.remove('is-active');
//   });

//   elAllSearchContent.forEach(content => {
//     content.classList.remove('is-active');
//   });
// }

// function section(parent:HTMLElement) {
//   const currentContent:HTMLElement = document.querySelector(`[data-tab-content="${parent.dataset.tab}"]`);

//   currentContent.classList.add('is-active');
//   parent.classList.add('is-active');
// }

// function activeTab() {
//   const products = document.querySelector('[data-search-total="products"]');
//   const res = document.querySelector('[data-search-total="resources"]');
//   const project = document.querySelector('[data-search-total="projectProfiles"]');
//   const news = document.querySelector('[data-search-total="newsrooms"]');
//   const other = document.querySelector('[data-search-total="other"]');
//   const count = '0';

//   if (group) {
//     document.querySelector('[data-tab-results].is-active').classList.remove('is-active');
//     document.querySelector('[data-search-results].is-active').classList.remove('is-active');

//     const autoSelectTab:HTMLButtonElement = document.querySelector(`[data-tab-results="${group}"]`);
//     autoSelectTab.classList.add('is-active');
//     const autoSelectTabContent = document.querySelector(`[data-tab-content="${autoSelectTab.dataset.tab}"]`);
//     autoSelectTabContent.classList.add('is-active');

//   } else if (products.innerHTML === count && res.innerHTML !== count) {
//     removeClass();
//     section(res.parentElement);
//   } else if (products.innerHTML === count && res.innerHTML === count && project.innerHTML !== count) {
//     removeClass();
//     section(project.parentElement);
//   } else if (products.innerHTML === count && res.innerHTML === count && project.innerHTML === count && news.innerHTML !== count) {
//     removeClass();
//     section(news.parentElement);
//   } else if (products.innerHTML === count && res.innerHTML === count && project.innerHTML === count && news.innerHTML === count && other.innerHTML !== count) {
//     removeClass();
//     section(other.parentElement);
//   } else {
//     removeClass();
//     section(products.parentElement);
//   }
// }

const updateContainers = (
  results: any,
  keyword: string,
  containers: NodeListOf<HTMLElement>
) => {
  containers.forEach((container) => {
    const containerGroup: string = container.dataset.searchResults;
    const searchTotal: HTMLElement = document.querySelector(
      `[data-search-total="${containerGroup}"]`
    );
    const hits: HTMLElement = container.querySelector('[data-hits]');
    const hitLimit: number = parseInt(hits.dataset.hitsLimit, 10);
    const hitType: string = hits.dataset.hits;
    const resultType: string = container.getAttribute('data-search-type');

    hits.innerHTML = ''; // Reset results container

    // Update total results number
    if (searchTotal) {
      searchTotal.innerText = results.hasOwnProperty('estimatedTotalHits')
        ? results.estimatedTotalHits
        : results.length;

      // If no results, display text from data-tab-results attribute on parent
      if (!results.estimatedTotalHits && !results.length) {
        hits.classList.add('is-empty');
        hits.innerHTML = `<strong>No ${searchTotal.parentElement.dataset.tabResults}</strong>`;
        hits.parentElement.classList.remove('is-active');
      } else {
        hits.classList.remove('is-empty');
      }
    }

    if (elSearchCount) {
      elSearchCount.innerText = hitCount.toString(); // Set results counter HTML
    }

    if (elSearchQuery) {
      elSearchQuery.innerText = keyword; // Put search keywords in quotations
    }

    const handleResult = (result: any, resultIndex: number) => {
      if (hitLimit) {
        if (resultIndex >= hitLimit) return;
      }

      if (result.getUrl || result.url || result.hitUrl) {
        hits.innerHTML += updateHTML(result, hitType, resultType);
      } else if (result[0]) {
        // Object of objects, need to reformat into array of objects
        const widenResult = result;
        // Remove non-object id property so the array of objects renders properly
        delete widenResult.id;

        // Create array of objects based on their values (discard keys)
        const widenGroup = Object.values(result);

        // Loop through array of Widen assets and create hits
        widenGroup.forEach((widenResult: any) => {
          hits.innerHTML += updateHTML(widenResult, hitType, resultType);
        });
      }
    };

    if (results.hits) {
      results.hits.forEach(handleResult);
    } else {
      results.forEach(handleResult);
    }
  });
};

async function searchIndex(
  meiliIndex: any,
  keyword: string,
  containers: NodeListOf<HTMLElement>,
  offset?: number
) {
  const index = await meiliIndex; // Get the index itself from the Promise first
  const hitsPerPage = 10;
  const params = {
    meiliIndex,
    keyword,
    containers,
  };

  const results: any = await index.search(keyword, {
    limit: hitsPerPage,
    offset: offset || 0,
  });
  const pages = results.estimatedTotalHits / hitsPerPage;

  hitCount += results.estimatedTotalHits; // Increase results counter

  updateContainers(results, keyword, containers);
  externalLinks();
}

async function searchCraft(
  keyword: string,
  craftSection: string,
  containers: NodeListOf<HTMLElement>
) {
  await fetch(
    `${url.origin}/actions/appmodule/search/search?q=${keyword}&section=${craftSection}`
  )
    .then((response) => response.json())
    .then((data) => {
      hitCount += data.length;
      updateContainers(data, keyword, containers);
    });

  externalLinks();
}

const debouncedSearch = debounce(300, (keyword: string) => {
  searchIndex(productIndex, keyword, elSearchResultsProducts);
  searchIndex(resourceIndex, keyword, elSearchResultsResources);
  if (elSearchResultsNewsrooms)
    searchIndex(newsroomIndex, keyword, elSearchResultsNewsrooms);
  if (elSearchResultsProjectProfiles) {
    // searchIndex(projectProfiles, keyword, elSearchResultsProjectProfiles);
    searchCraft(keyword, 'projectProfiles', elSearchResultsProjectProfiles);
  }
  searchCraft(keyword, null, elSearchResultsOther);
});

function SearchMenu() {
  // Search results page
  if (query && elSearchQuery) {
    Promise.all([
      searchIndex(productIndex, query, elSearchResultsProducts),
      searchIndex(resourceIndex, query, elSearchResultsResources),
      searchIndex(newsroomIndex, query, elSearchResultsNewsrooms),
      // searchIndex(projectProfiles, query, elSearchResultsProjectProfiles),
      searchCraft(query, 'projectProfiles', elSearchResultsProjectProfiles),
      searchCraft(query, null, elSearchResultsOther),
    ]).then(() => externalLinks());
    elSearchQuery.innerText = query;
  }

  // Header nav
  if (elSearch && elSearchTriggers && elSearchInputs) {
    elSearchTriggers.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        elSearch.classList.toggle('is-active');

        if (elSearch.classList.contains('is-active')) {
          elSearchInputs.forEach((input, index) => {
            const searchInput = input;

            // First input on page (search in header)
            if (index === 0) {
              setTimeout(() => {
                searchInput.focus(); // Allow input to become visible so focus can work
              }, 50);
            }
          });
        } else {
          document.body.classList.remove('is-search-open');
          elSearch.classList.remove('is-touched');
        }
      });
    });

    if (elSearchOverlay) {
      elSearchOverlay.addEventListener('click', () => {
        document.body.classList.remove('is-search-open');
        elSearch.classList.remove('is-active', 'is-touched');
        elSearchInputs.forEach((input) => {
          const searchInput = input;
          searchInput.value = '';
        });
      });
    }
  }

  // Search input functionality
  if (elSearchInputs) {
    elSearchInputs.forEach((input) => {
      const searchInput = input;
      searchInput.value = query;

      // Only open the header search menu if using the header search input
      if (searchInput.dataset.searchInput === 'menu') {
        searchInput.addEventListener('input', () => {
          document.body.classList.toggle(
            'is-search-open',
            searchInput.value.length > 0
          );
          elSearch.classList.toggle('is-touched', searchInput.value.length > 0);
        });
      }

      // On search input change event
      // - Check if key is Esc
      // - Update Meliisearch results
      searchInput.addEventListener(
        'keyup',
        ({ key, currentTarget }: KeyboardEvent) => {
          const searchInputEl = <HTMLInputElement>currentTarget;
          hitCount = 0;

          if (key === 'Escape') {
            // If search field has content, clear on Esc
            // Else, close the search
            if (searchInputEl.value.length > 0) {
              searchInputEl.value = '';
            } else {
              searchInput.blur();
              if (searchInputEl) {
                document.body.classList.remove('is-search-open');
                searchInputEl.classList.remove('is-active', 'is-touched');
              }
            }
          }

          if (searchInputEl.value.length > 0) {
            debouncedSearch(searchInputEl.value);

            elMoreResultsButtons.forEach(
              (moreResultsButton: HTMLAnchorElement) => {
                const button = moreResultsButton;
                button.href = `/search?q=${searchInputEl.value}&group=${button.dataset.searchMore}`;
              }
            );
          }
        }
      );

      searchInput.addEventListener(
        'blur',
        ({ currentTarget }: KeyboardEvent) => {
          const searchInputEl = <HTMLInputElement>currentTarget;
          (window as any).dataLayer.push({
            event: 'instantSearch',
            query: searchInputEl.value,
          });

          (window as any).dataLayer.push({
            event: 'instantSearch', //populate the event exactly as written
            searchQuery: searchInputEl.value, //populate with what was searched
            numOfResults:
              document.querySelectorAll('.Search-results-card').length +
              document.querySelectorAll('.Search-results-button').length, //populate with the number of results
            ...createBasicParams()
          });
        }
      );
    });
  }

  if (elSearchClear) {
    elSearchClear.addEventListener('click', () => {
      document.body.classList.remove('is-search-open');
      elSearch.classList.remove('is-active', 'is-touched');
    });
  }
}

export default SearchMenu;

import { createBasicParams } from "./ga4-events";

const inputZip = document.querySelector<HTMLInputElement>(
  '[data-zipcodes-input]'
);
const ctaZip = document.querySelector<HTMLAnchorElement>('[data-zipcodes-cta]');
const searchResult = document.querySelector<HTMLSpanElement>(
  '[data-zipcodes-result]'
);
const zipCodesHtmlList = document.querySelectorAll(
  '[data-zipcodes-served]'
) as NodeListOf<HTMLDivElement>;

const corporateContainer = document.querySelector<HTMLDivElement>(
  '[data-corporate-div]'
);
const corporateCtas = document.querySelectorAll<HTMLElement>(
  '[data-corporate-cta]'
);

const scrollTo = (offset: number) => {
  window.scroll({
    top: offset,
    behavior: 'smooth',
  });
};

const corporateClickHandler = (el: MouseEvent) => {
  el.preventDefault();

  const { offsetTop } = corporateContainer;
  scrollTo(offsetTop - 160);
};

const filterList = (text: string) => {
  if (zipCodesHtmlList.length <= 0) return;

  let haveResult = false;
  zipCodesHtmlList.forEach((el: HTMLDivElement) => {
    const { zipcodesServed } = el.dataset;
    const exist = zipcodesServed.includes(text);

    if (exist) {
      haveResult = true;
      const { offsetTop } = el;
      scrollTo(offsetTop - 210);

      return false;
    }
  });
  if (haveResult) searchResult.classList.add('hidden');
  else searchResult.classList.remove('hidden');
};

const feedDataLayer = (zipCode: string) => {
  (window as any).dataLayer.push({
    event: 'ZipCodeSearch', // Pass in this static value whenever someone searches for zip code on expert connection page
    searchQuery: zipCode, // Pass in the actual zip code that was searched on the site
    ...createBasicParams(),
  });
};

const initFiltering = () => {
  const { value } = inputZip;
  if (value.length <= 0) {
    return;
  }
  feedDataLayer(value);
  filterList(value);
};

const initExpertConnection = () => {
  if (ctaZip) {
    ctaZip.addEventListener('click', (el: MouseEvent) => {
      el.preventDefault();
      initFiltering();
    });
  }
  if (inputZip) {
    inputZip.addEventListener('keyup', (el: KeyboardEvent) => {
      if (el.key === 'Enter') initFiltering();
    });
  }

  if (corporateCtas && corporateContainer) {
    corporateCtas.forEach((el: HTMLElement) =>
      el.addEventListener('click', corporateClickHandler.bind(el))
    );
  }
};

export default initExpertConnection;

export default function onInit() {
  const { body } = document;
  const headerSubmenus = document.querySelectorAll("[data-header-submenu]");
  const submenuTriggers = document.querySelectorAll("[data-link-trigger]");
  const ngConnectsBanner = document.querySelector("#ngconnects-banner");
  const ngConnectsClose = document.querySelector("#close-ngconnects");
  const stickyHeader: HTMLElement = document.querySelector("[data-header]");

  // NGConnects Banner
  function setCookie(cookie: string, value: Boolean, days: any) {
    document.cookie = `${cookie}=${value};path=/`;
  }

  if (ngConnectsClose) {
    ngConnectsClose.addEventListener('click', function () {
      ngConnectsBanner.remove();
      setCookie("hideAlert", true, 1);
    });
  }

  // Sticky Nav
  const topOfStickyHeader = stickyHeader.offsetTop + 40;

  function stickyNav() {
    if (window.pageYOffset > topOfStickyHeader) {
      stickyHeader.classList.add("fixed-nav");
    } else {
      stickyHeader.classList.remove("fixed-nav");
    }
  }

  window.addEventListener("load", stickyNav);
  window.addEventListener("scroll", stickyNav);

  const menuItems = Array.from(document.querySelectorAll("[data-header-link]"));
  const currentPath = window.location.pathname;

  menuItems.forEach((item: HTMLAnchorElement) => {
    if (currentPath.startsWith(item.pathname)) {
      item.classList.add("is-active");
    }
  });

  // SubMenus
  submenuTriggers.forEach((trigger: HTMLElement) => {
    headerSubmenus.forEach((submenu: HTMLElement) => {
      if (trigger.dataset.linkTrigger == submenu.dataset.headerSubmenu) {
        trigger.classList.add("has-subMenu");
      }
    });
  });

  function closeSubmenu(e: any) {
    if (!e.target.classList.contains("is-open")) {
      const openSubmenuEl = document.querySelector(
        "[data-header-submenu].is-open"
      );
      const openTriggerEl = document.querySelector(
        "[data-link-trigger].is-open"
      );

      if (openSubmenuEl) {
        openSubmenuEl.classList.remove("is-open");
        openTriggerEl.classList.remove("is-open");
        body.classList.remove("is-submenuOpen");
        body.removeEventListener("mouseover", closeSubmenu);
      }
    }
  }

  function openSubmenu(e: any) {
    const submenuName = e.target.dataset.linkTrigger;
    const { noOverlay } = e.target.dataset;

    headerSubmenus.forEach((submenu) => {
      submenu.classList.remove("is-open");
    });

    submenuTriggers.forEach((submenu) => {
      submenu.classList.remove("is-open");
    });

    if (submenuName) {
      const submenu = document.querySelector(
        `[data-header-submenu="${submenuName}"]`
      );

      if (submenu) {
        const submenuChiildren = submenu.querySelectorAll("*");

        submenuChiildren.forEach((element) => {
          element.classList.add("is-open");
        });

        e.target.classList.add("is-open");
        submenu.classList.add("is-open");

        if (!noOverlay) {
          body.classList.add("is-submenuOpen");
        }
      }
    }
  }

  function onMouseLeave(_e: MouseEvent) {
    body.addEventListener("mouseover", closeSubmenu);
  }

  submenuTriggers.forEach((trigger) => {
    trigger.addEventListener("mouseenter", openSubmenu);
    trigger.addEventListener("mouseleave", onMouseLeave);
  });
}

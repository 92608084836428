import MarkerClusterer from "@googlemaps/markerclustererplus";
import mapDarkStype from "./map-dark-style";

interface MarkerData {
  lat: number;
  lng: number;
  title: string;
  address: string;
  distance: number;
}

let map: google.maps.Map;
let infoWindow: google.maps.InfoWindow;
let bounds: google.maps.LatLngBounds;
let markers = new Array<google.maps.Marker>();
let markerClusterer: MarkerClusterer;
let mapOptions: google.maps.MapOptions = {
  maxZoom: 15,
  // zoom: 10,
  center: { lat: 34.5, lng: -96.4 },
  disableDefaultUI: true,
  zoomControl: true,
};

const searchRadiusSelect: HTMLSelectElement = document.querySelector(
  "[data-map-radius]"
);
const filterForm: HTMLFormElement = document.querySelector(
  "[data-filter-form]"
);

const mapEl: HTMLElement = document.querySelector("[data-map]");
const mapHaveDarkLayout = mapEl && mapEl.dataset.mapDark !== undefined;

function openInfoWindow(currMarker: google.maps.Marker, index: number) {
  const listLocations: HTMLElement[] = Array.from(
    document.querySelectorAll("[data-location]")
  );
  map.panTo(currMarker.getPosition());
  let content: string;

  if (infoWindow) {
    infoWindow.close();
  }

  if (listLocations) {
    content = listLocations[index] ? listLocations[index].innerHTML : "";
  }

  infoWindow = new google.maps.InfoWindow({
    content:
      content ||
      `
        <div class="w-64 p-5">
          <h6 class="font-primary text-xl leading-7 font-bold mb-5 tracking-no-spacing text-primary-darker">${
            currMarker.get("title") || ""
          }</h6>
          <address class="w-2/3 font-primary text-lg leading-8 font-normal not-italic">${
            currMarker.get("address") || ""
          }</address>
        </div>
      `,
  });

  infoWindow.open(map, currMarker);
}

function createMarker(position: MarkerData, index: number) {
  const isActive = index === 0;
  const markerSize = { width: 28, height: 40 };
  const siteHandle: string = document.body.dataset.site;

  const defaultMarkerImage = {
    url: "/dist/img/icons/map-marker.svg",
    scaledSize: new google.maps.Size(markerSize.width, markerSize.height),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(markerSize.width / 2, markerSize.height),
  };

  const activeMarkerImage = {
    ...defaultMarkerImage,
    url: `/dist/img/icons/map-marker-active-${siteHandle}.svg`,
  };

  const setMarkerDefaultIcons = (thisMarker: google.maps.Marker) => {
    markers.forEach((currMarker: google.maps.Marker) => {
      currMarker.setIcon(defaultMarkerImage);
    });

    thisMarker.setIcon(activeMarkerImage);
  };

  const marker = new google.maps.Marker({
    draggable: false,
    icon: isActive ? activeMarkerImage : defaultMarkerImage,
    map: null,
    position,
  });

  const { title, address, distance } = position;

  marker.set("title", title);
  marker.set("address", address);
  marker.set("distance", distance);

  google.maps.event.addListener(marker, "click", () => {
    openInfoWindow(marker, index);
    setMarkerDefaultIcons(marker);
  });

  return marker;
}

function initMarkerClustered(_map: google.maps.Map) {
  markerClusterer = new MarkerClusterer(_map, markers, {
    maxZoom: 10,
    gridSize: 40,
    styles: [
      {
        width: 24,
        height: 24,
        className: "custom-clustericon-1",
      },
      {
        width: 28,
        height: 28,
        className: "custom-clustericon-2",
      },
      {
        width: 32,
        height: 32,
        className: "custom-clustericon-3",
      },
    ],
    clusterClass: "custom-clustericon",
  });
}

function reloadWhereToBuyPage(zipCode: string, radius: string) {
  window.location.href = `/where-to-buy?zipcode=${zipCode}&radius=${radius}#map-container`;
}

function updateMap() {
  const element = document.querySelector(
    "[data-default-locations]"
  ) as HTMLElement;

  // Get the data attribute value
  const dataAttribute = element.dataset.defaultLocations;

  // Decode the JSON string into a JavaScript object
  const locations = JSON.parse(dataAttribute);

  const mapResultContainer = document.getElementById("map-container-results");

  if (mapResultContainer) {
    mapResultContainer.innerHTML = "";

    if (locations.length === 0) {
      mapResultContainer.innerHTML = `
        <div class="text-center text-2xl">
            <img src="/dist/img/icons/no-search-results.svg"
                alt="no-search-results-icon"
                class="mx-auto mb-4" />
            <p>'There are no results for your search. Please expand your search radius or try searching another location.'</p>
        </div>`;
    }
  }

  locations.forEach((location: any) => {
    const item = document.createElement("div");
    item.classList.add(
      "Map-list-item",
      "w-full",
      "py-10",
      "border-b",
      "border-gray-300"
    );
    item.dataset.location = JSON.stringify({
      id: location.id,
      lat: Number(location.lat),
      lng: Number(location.lng),
    });

    // Create header
    const header = document.createElement("header");
    header.classList.add("Map-list-item-header", "flex", "mb-3");

    const headerContent = document.createElement("div");
    headerContent.classList.add(
      "flex",
      "flex-col",
      "justify-center",
      "ml-0",
      "[",
      "lg:ml-0",
      "]"
    );

    const h3 = document.createElement("h3");
    h3.classList.add("text-2xl", "font-bold");
    h3.textContent = location.title;

    const pDistance = document.createElement("p");
    pDistance.classList.add("italic");
    pDistance.textContent = `${location.distance} miles away`;

    headerContent.appendChild(h3);
    headerContent.appendChild(pDistance);
    header.appendChild(headerContent);

    // Create info group
    const infoGroup = document.createElement("div");
    infoGroup.classList.add(
      "Map-list-item-info-group",
      "flex",
      "flex-wrap",
      "w-full",
      "justify-between",
      "[",
      "md:flex-row",
      "md:items-end",
      "]"
    );

    // Address section
    const address = document.createElement("address");
    address.classList.add(
      "text-lg",
      "not-italic",
      "leading-relaxed",
      "max-w-xs",
      "w-2/3",
      "mb-4",
      "[",
      "md:w-3/4",
      "md:mb-0",
      "]"
    );
    address.textContent = location.address;

    const phoneLink = document.createElement("a");
    phoneLink.href = `tel:${location.phone}`;
    phoneLink.classList.add("block");
    phoneLink.textContent = location.phone;

    address.appendChild(phoneLink);

    // Directions link
    const directionsDiv = document.createElement("div");
    directionsDiv.classList.add("flex");

    const directionsLink = document.createElement("a");
    directionsLink.classList.add(
      "font-bold",
      "pt-3",
      "pb-1",
      "border-b-2",
      "border-primary-base"
    );
    directionsLink.href = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      location.address
    )}`;
    directionsLink.setAttribute("rel", "noopener noreferrer");
    directionsLink.setAttribute("target", "_blank");
    directionsLink.textContent = "Directions";

    directionsDiv.appendChild(directionsLink);

    // Append all parts
    infoGroup.appendChild(address);
    infoGroup.appendChild(directionsDiv);

    item.appendChild(header);
    item.appendChild(infoGroup);

    mapResultContainer.appendChild(item);
  });
  initMap();
}

// Function to check location permission status and handle zip code param
async function checkLocationPermission() {
  try {
    const permission = await navigator.permissions.query({
      name: "geolocation",
    });
    const mapResultContainer = document.getElementById("map-container-results");
    const loadingContainer = document.getElementById("loading-container");

    displayLocationIcon();

    if (permission.state === "granted" && getZipCodeParam()) {
      // If location is granted and no zip code param, set "Current Location"
      setLocationInput("Current Location");
      mapResultContainer.style.display = "none";
      loadingContainer.classList.remove("hidden");
      setTimeout(() => {
        loadingContainer.classList.add("hidden");
        mapResultContainer.style.display = "block";
      }, 3000);
    }

    // Listen for changes in permission state
    permission.onchange = () => {
      if (permission.state === "granted" && getZipCodeParam()) {
        setLocationInput("Current Location");
        mapResultContainer.style.display = "none";
        loadingContainer.classList.remove("hidden");
        setTimeout(() => {
          loadingContainer.classList.add("hidden");
          mapResultContainer.style.display = "block";
        }, 3000);
      }
    };
  } catch (error) {
    console.error("Error checking location permission:", error);
  }
}

// Helper function to get zip code param from URL
function getZipCodeParam() {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    !urlParams.has("zipcode") || urlParams.get("zipcode") === "Current Location"
  );
}

// Function to set the input value
function setLocationInput(value: string) {
  const locationInput = document.getElementById(
    "address-input"
  ) as HTMLInputElement;
  if (locationInput) {
    locationInput.value = value;
  }
}

// Function to display the location icon
function displayLocationIcon() {
  const svgContainer = document.getElementById("geolocation");
  if (svgContainer) {
    svgContainer.style.display = "block";

    svgContainer.addEventListener("click", requestLocation);
  }
}

// Function to remove the location icon
function removeLocationIcon() {
  const svgContainer = document.getElementById("geolocation");
  svgContainer.style.display = "none";
}

// Function to request location access
function requestLocation() {
  const mapResultContainer = document.getElementById("map-container-results");
  const loadingContainer = document.getElementById("loading-container");

  navigator.geolocation.getCurrentPosition(
    (position) => {
      console.log("Location enabled:", position.coords);
      mapResultContainer.style.display = "none";
      loadingContainer.classList.remove("hidden");
      setTimeout(() => {
        loadingContainer.classList.add("hidden");
        mapResultContainer.style.display = "block";
      }, 3000);
      setLocationInput("Current Location");
      updateMap();
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        console.log("Location access denied by user.");
      }
    }
  );
}

export default function initMap() {
  // Run the check
  checkLocationPermission();

  const listLocations: HTMLElement[] = Array.from(
    document.querySelectorAll("[data-location]")
  );

  const markerLocations =
    mapEl && listLocations
      ? listLocations.map((location) => JSON.parse(location.dataset.location))
      : null;

  if (mapEl) {
    if (mapHaveDarkLayout) {
      mapOptions = {
        ...mapOptions,
        center: { lat: 38.503082, lng: -98.464536 },
      };
    }

    bounds = new google.maps.LatLngBounds();
    map = new google.maps.Map(
      document.querySelector("[data-map]") as HTMLElement,
      mapOptions
    );

    if (mapHaveDarkLayout) {
      map.setOptions({ styles: mapDarkStype });
    }

    if (markerLocations && markerLocations.length > 0) {
      markerLocations.forEach((location: MarkerData, index: number) => {
        const markerCreated = createMarker(location, index);
        map.fitBounds(bounds.extend(markerCreated.getPosition()));
        markers.push(markerCreated);
      });
    } else {
      // No marker locations, try to center map on user's location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            map.setCenter(userLocation);
            map.setZoom(12);
          },
          () => {
            // If user denies location access or an error occurs, fallback to default location
            map.setCenter({ lat: 38.503082, lng: -98.464536 });
            map.setZoom(5); // Default zoom level
          }
        );
      } else {
        // Geolocation not supported, fallback to default location
        map.setCenter({ lat: 38.503082, lng: -98.464536 });
        map.setZoom(5);
      }
    }

    markerClusterer = new MarkerClusterer(map, markers, {
      maxZoom: 10,
      gridSize: 40,
      styles: [
        {
          width: 24,
          height: 24,
          className: "custom-clustericon-1",
        },
        {
          width: 28,
          height: 28,
          className: "custom-clustericon-2",
        },
        {
          width: 32,
          height: 32,
          className: "custom-clustericon-3",
        },
      ],
      clusterClass: "custom-clustericon",
    });
  }

  const inputField = document.getElementById("address-input");

  if (inputField) {
    inputField.addEventListener("input", function (event: any) {
      if (event.target.value.length >= 15) {
        event.target.value = "";
      }
    });
  }

  if (searchRadiusSelect && filterForm) {
    searchRadiusSelect.addEventListener("change", () => {
      filterForm.submit();
    });
  }
}

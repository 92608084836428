import hashValue from '../third-party/hash';

let date = new Date();
let siteName = (<HTMLMetaElement>(
  document.head.querySelector('[property="og:site_name"]')
)).content;

const generateSessionId = () => {
  let sessionId = sessionStorage.getItem('sessionId');
  if (sessionId) {
    return sessionId;
  } else {
    sessionId = Math.random().toString(36).substring(2, 9);
    sessionStorage.setItem('sessionId', sessionId);
    return Math.random().toString(36).substring(2, 9);
  }
};

const generateUserId = () => {
  let userId = sessionStorage.getItem('userId');
  if (userId) {
    return userId;
  } else {
    userId = `${Math.floor(Math.random() * 100)}_${generateSessionId()}`;
    sessionStorage.setItem('userId', userId);
    return userId;
  }
};

export const createBasicParams = () => {
  return {
    userId: generateUserId(), //populate with the user ID from the CRM
    sessionId: generateSessionId(), //populate with a custom value for the session ID
    hitTimeStamp: date.toISOString(), //populate with the current UTC time stamp when a user loaded this page in this format (year-month-dayThour:minutes:seconds.milliseconds timezone_offset)
  };
};

export const getClientId = () => {
  let clientId = '';
  let ga: any;

  if (typeof ga === 'function') {
    // index 0 because there is only 1 tracker
    clientId = ga.getAll()[0].get('clientId');
  }
  return clientId;
};

// Materials Download Event
export const materialsDownloadEvent = (event: {
  name: string;
  cta: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    ctaOption: event.cta, //populate with the option selected to get the plan (Download, Print, Email)
    ...createBasicParams(),
  });
};

// Submittal Builder Funnel Event
export const submittalBuilderFunnel = (event: {
  name: string;
  step: string;
  stepText: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    submittalBuilderStep: event.step, //populate with the step of the submittal builder:
    // 1 = Select Documents
    // 2 = Project Detail
    // 3 = Download
    submittalBuilderStepText: event.stepText, //populate with the submittal builder step text
    ...createBasicParams(),
  });
};

// Submittal Builder Download Event
export const submittalBuilderDownload = (event: {
  name: string;
  downloadSelection: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    downloadSelection: event.downloadSelection, //populate with the download selection
    userEmailHashed: '', //populate with users hashed email, leave blank for guest - guest email
    userEmail: '', //populate with users email, leave blank for guest - guest email
    submittalBuilderStepText: 'Download',
    submittalBuilderStep: '3',
    ...createBasicParams(),
  });
};

// Submittal Builder Type Selection Event
export const submittalBuilderTypeSelection = (event: {
  name: string;
  docSelected: string;
  typeSelected: string;
  brandSelected: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    docSelected: event.docSelected, //populate with the title of the document selected
    typeSelected: event.typeSelected, //populate with the type of the document selected
    brandSelected: event.brandSelected, //populate with the brand of the doc selected
    userEmailHashed: '', //populate with users hashed email, leave blank for guest - guest email
    userEmail: '', //populate with users email, leave blank for guest - guest email
    submittalBuilderStepText: 'Select Documents',
    submittalBuilderStep: '1',
    ...createBasicParams(),
  });
};

// International Distributors Event
export const internationalDistributorsEvent = (event: {
  name: string;
  country: string;
  contactOption: string;
  textSelected: string;
  contactName: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    countryDropdown: event.country, //populate with Finland, France, Greece, Ireland, Italy, New Zealand, Portugal, or United Kingdom only if selected from dropdown
    contactOption: event.contactOption, //populate with call or email
    region: event.country, //populate with international distributor country
    contactType: 'Construction Design', //populate with either Customer Accounts or Construction Design
    contactName: event.contactName, //populate with the person listed as the expert for this selection
    textSelected: event.textSelected, //populate with the text of what was clicked, either the phone number or "email"
    componentName: 'International Distributors', //populate with the name of the component from the CMS
    ...createBasicParams(),
  });
};

// Submittal Builder Project Details
export const submittalBuilderProjectDetails = () => {
  let email = (<HTMLInputElement>document.querySelector("[name='email']"))
    .value;

  (window as any).dataLayer.push({
    event: 'submittalBuilderProjectDetails', //populate the event exactly as written
    userEmail: email, //populate with the email
    userEmailHashed: hashValue(email), //populate with the hashed email
    submittalDate: (<HTMLInputElement>(
      document.querySelector("[name='submittalDate']")
    )).value,
    jobName: (<HTMLInputElement>document.querySelector("[name='jobName']"))
      .value, //populate with the job name entered
    jobState: (<HTMLSelectElement>document.querySelector("[name='jobState']"))
      .value, //populate with the user’s state
    jobCity: (<HTMLInputElement>document.querySelector("[name='jobCity']"))
      .value, //populate with the user’s city
    jobRole: (<HTMLSelectElement>document.querySelector("[name='jobRole']"))
      .value, //populate with the job role
    companyName: (<HTMLInputElement>document.querySelector("[name='company']"))
      .value, //popuate with the compny name
    subscription: (<HTMLSelectElement>(
      document.querySelector("[name='receiveOnboardNewsletterr']")
    )).value, //populate yes or no depending on the selection for the newsletter
    submittalBuilderStepText: 'Project Details',
    submittalBuilderStep: '2',
    ...createBasicParams(),
  });
};

// FAQ Interaction Event
export const faqInteraction = (event: {
  name: string;
  faqSelected: string;
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    faqSelected: event.faqSelected, //populate with the faq question
    ...createBasicParams(),
  });
};

// Materials Calculator Event
export const materialsCalculatorEvent = (event: {
  name: string;
  dimensions: {
    length: number;
    width: number;
    height: number;
    ceiling: boolean;
  };
}) => {
  (window as any).dataLayer.push({
    event: event.name, //populate the event exactly as written
    roomLength: event.dimensions.length, //populate with the room length
    roomWidth: event.dimensions.width, //populate with the room width
    roomheight: event.dimensions.height, //populate with the room height
    includeCeiling: event.dimensions.ceiling ? 'Yes' : 'No', //populate with uyes when a user selects to include ceiling
    ...createBasicParams(),
  });
};

// Submittal Builder Doc Selection Event
export const submittalBuilderDocSelectionEvent = (event: {
  doc: string;
  brand: string;
}) => {
  (window as any).dataLayer.push({
    event: 'submittalBuilderDocSelection',
    docSelected: event.doc,
    brandSelected: event.brand,
    ...createBasicParams(),
  });
};

export default function onInit() {
  let links = document.querySelectorAll('[title]');
  let relatedProducts = document.getElementById('related-products');
  let zipCodeSearch = <HTMLInputElement>(
    document.getElementById('address-input')
  );
  let projectDetailsForm = document.querySelector(
    '[data-handle="submittalBuilder"]'
  );
  let carouselCards = document.querySelectorAll('[data-carousel-full-card]');
  let nearestRetailers = document.querySelectorAll('[data-nearest-retailer]');
  let blogForm = document.querySelector('#blog-form');
  let newsletterCards = document.querySelectorAll(
    '[data-card-type="newsletters"]'
  );
  let newsroomCards = document.querySelectorAll(
    '[data-component="newsroomOverview"] [data-card-type]'
  );
  let blogDetail = document.querySelectorAll('[data-blog-detail]');
  let expertConnectionList = document.querySelectorAll(
    '#expert-connection-listing-card [data-zipcodes-served]'
  );
  let socialLinks = document.querySelectorAll(
    'footer.Footer a[href*="facebook.com"], footer.Footer a[href*="instagram.com"], footer.Footer a[href*="linkedin.com"], footer.Footer a[href*="twitter.com"], footer.Footer a[href*="youtube.com"]'
  );
  let footerLinks = document.querySelectorAll(`footer.Footer a[href*="${window.location.hostname}"], footer.Footer a[href*="phh.tbe.taleo.net"]`);
  let headerLinks = document.querySelectorAll('header.Header a, #MobileMenu a');
  let miniNavLinks = document.querySelectorAll(".StickyNav a");
  let eventAdded = false;

  // Carousel Click Event
  if (carouselCards) {
    carouselCards.forEach((card) => {
      card.addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: 'carouselClick', //populate the event exactly as written
          carouselClick: card.textContent, //populate with the CTA text
          carouselModal: card.getAttribute('data-card-title'), //populate with the modal selected
          componentName: card.getAttribute('data-carousel-full-card'), //populate with the name of the component from the CMS
          ...createBasicParams(),
        });
      });
    });
  }

  // PDF Download Event
  if (links) {
    links.forEach((downloadBtn: any) => {
      downloadBtn.addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: 'contentdownload',
          pdfFileName: downloadBtn.getAttribute('title'), //populate with the click text associated with the featured content
          ...createBasicParams(),
        });
      });
    });
  }

  // Zip Code Search Event
  if (zipCodeSearch) {
    document
      .querySelector('[data-address-input-submit]')
      .addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: 'ZipCodeSearch', // Pass in this static value whenever someone searches for zip code on expert connection page
          searchQuery: zipCodeSearch.value, // Pass in the actual zip code that was searched on the site
          ...createBasicParams(),
        });
      });
  }

  // Location Interaction Event
  if (nearestRetailers) {
    nearestRetailers.forEach((retailer) => {
      retailer.querySelectorAll('a').forEach((retailerButton) => {
        retailerButton.addEventListener('click', () => {
          (window as any).dataLayer.push({
            event: 'locationInteraction', //populate the event exactly as written
            retailerDistance: `${(<Element>(
              retailerButton.parentNode
            )).getAttribute('data-nearest-distance')} miles away`, //populate with the distance from retailer
            retailerName: (<Element>retailerButton.parentNode).getAttribute(
              'data-nearest-title'
            ), //populate with the name of the retailer
            retailerState: (<Element>retailerButton.parentNode).getAttribute(
              'data-nearest-state'
            ), //populate with the state of the retailer
            retailerCity: (<Element>retailerButton.parentNode).getAttribute(
              'data-nearest-city'
            ), //populate with the city of the retailer
            ...createBasicParams(),
          });
        });
      });
    });
  }

  // Blog Search Event
  if (blogForm) {
    blogForm.addEventListener('submit', () => {
      (window as any).dataLayer.push({
        event: 'blogSearch', //populate the event exactly as written
        searchQuery: (<HTMLInputElement>document.getElementById('pp-search'))
          .value,
        //populate with what was searched
        numOfResults: '1', //populate with the number of results
        ...createBasicParams(),
      });
    });
  }

  // Newsletter Click
  if (newsletterCards) {
    newsletterCards.forEach((card) => {
      card.querySelectorAll('a').forEach((node) => {
        node.addEventListener('click', () => {
          (window as any).dataLayer.push({
            event: 'newsletterClick',
            newsletterTitle: card.getAttribute('data-title'),
            ...createBasicParams(),
          });
        });
      });
    });
  }

  // Blog Click Event
  if (blogDetail) {
    blogDetail.forEach((detail) => {
      detail.addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: 'blogClick', //populate the event exactly as written
          blogClick: detail.getAttribute('data-blog-title'), //populate with the blog post title
          blogCategory: detail.getAttribute('data-blog-category'), //populate with the blog category
          'component Name': 'Blog Detail', //populate with the name of the component from the CMS
          position: detail.getAttribute('data-position'), //populate with the number position of the item
          ...createBasicParams(),
        });
      });
    });
  }

  // Expert Connection Event
  if (expertConnectionList) {
    expertConnectionList.forEach((card) => {
      const region = card.getAttribute('data-region');
      const contactName = card.getAttribute('data-contact-name');
      const componentName = card.getAttribute('data-component');

      card.querySelectorAll('a').forEach((linkEl) => {
        linkEl.addEventListener('click', () => {
          const contactOption = linkEl.getAttribute('data-contact-option');
          let textSelected = linkEl.textContent;

          if (contactOption == 'Email') {
            textSelected = linkEl.getAttribute('href');
            textSelected = textSelected.replace('mailto:', '');
          }

          (window as any).dataLayer.push({
            event: 'expertConnection',
            contactOption: contactOption,
            region: region,
            contactType: linkEl.getAttribute('data-contact-type'),
            contactName: contactName,
            textSelected: textSelected,
            componentName: componentName,
          });
        });
      });
    });
  }

  // Social Links Event
  if (socialLinks) {
    socialLinks.forEach((node) => {
      node.addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: 'socialLink',
          socialIcon: node.getAttribute('aria-label'),
          ...createBasicParams(),
        });
      });
    });
  }

  // Footer Links Event
  if(footerLinks) {
    footerLinks.forEach((node) => {
      node.addEventListener('click', () => {
        let footerNav = node.textContent;
        if(footerNav == '')
          footerNav = 'Logo';
        (window as any).dataLayer.push({
          event: "footerClick",
          footerNav: footerNav,
          ...createBasicParams()
        });
      });
    });
  }

  // Header links 
  // - Click - Nav 
  // - Click - Nav Images
  if(headerLinks) {
    headerLinks.forEach(node => {
      node.addEventListener("click", () => {
        let topNav = "";
        let secondaryNav = "";

        if(node.classList.contains("SubNav-image-container")) {
          const imageText = node.querySelector("h3").textContent;
          (window as any).dataLayer.push({
            event: "navImageClicks",
            topNav: getTopLevelMenuLabel(),
            imageSelected: imageText,
            ...createBasicParams()
          });
        } else {
          if(isTopLevelMenu(node)) { 
            topNav = node.textContent;
          } else {
            topNav = getTopLevelMenuLabel();
            secondaryNav = node.textContent;
          }
  
          if(topNav == '')
            topNav = "Logo";
  
          (window as any).dataLayer.push({
            event: "navClicks",
            topNav: topNav,
            secondaryNav: secondaryNav,
            ...createBasicParams()
          });
        }
      });
    })
  }

  const isTopLevelMenu = (menuEl: Element) => {
    const parent = <HTMLElement>menuEl.parentNode;
    let isTopLevel = false;
    if(parent.classList.contains("has-subMenu") || parent.classList.contains("Header-topNav"))
      isTopLevel = true;
    else if(document.querySelector("#MobileMenu.is-open") && !document.querySelector(".MobileMenu-subMenus-menu.is-open"))
      isTopLevel = true
    return isTopLevel;
  }

  const getTopLevelMenuLabel = () => {
    const topLevelMenu = document.querySelector(".has-subMenu.is-open a, p.has-subMenu.is-open");
    const topLevelMenuMobile = document.querySelector(".MobileMenu-subMenus-menu.is-open");
    
    let menuLabel = '';
    if(topLevelMenu) 
      menuLabel = topLevelMenu.textContent;
    else if(topLevelMenuMobile)
      menuLabel = topLevelMenuMobile.getAttribute("data-submenu");
    return menuLabel;
  }

  // Newsroom Selection
  if(newsroomCards) {
    newsroomCards.forEach(card => {
      const title = card.getAttribute("data-title");
      const category = card.getAttribute("data-card-type")

      card.querySelectorAll("a").forEach(node => {
        node.addEventListener("click", () => {
          (window as any).dataLayer.push({
            event: "newsroomSelection",
            newsTitle: title,
            newsCategory: category,
            ...createBasicParams()
          });
        });
      });
    });
  }

  // Mini Nav Event
  if(miniNavLinks) {
    miniNavLinks.forEach(node => {
      node.addEventListener('click', () => {
        (window as any).dataLayer.push({
          event: "miniNavClicked",
          navClicked: node.textContent,
          ...createBasicParams()
        });
      });
    });
  }

  if (window.location.search.includes('step=2') && projectDetailsForm) {
    projectDetailsForm.addEventListener('submit', () => {
      submittalBuilderProjectDetails();
    });
  }

  if (
    window.location.pathname.includes('products') &&
    document.querySelector('[data-product-title]')
  ) {
    let category = (<HTMLInputElement>(
      document.querySelector('[data-product-category]')
    )).getAttribute('data-product-category');
    let title = (<HTMLInputElement>(
      document.querySelector('[data-product-title]')
    )).getAttribute('data-product-title');

    (window as any).dataLayer.push({ ecommerce: null });

    (window as any).dataLayer.push({
      pageType: 'pdp',
      pageCategory: category, //populate with the page category or pageType if not applicable
      pageTitle: document.title, //populate with page meta title
      pageName: `pdp: ${document.title}`, //populate with <pageType: pageTitle>
      pageBreadcrumb: `products > plp ${category} > pdp ${title}`, //populate with url breadcrumb
      hitPayLoad: '50', //The idea is that you can monitor if you are approaching the 8192 byte limit, since hits that equal or surpass that limit will not get sent to Google Analytics.
      pageCountryCode: 'US', //populate with the 2 character page country code, if only US then populate with US
      userEmailHashed: '', //populate with users hashed email, leave blank for guest
      userEmail: '', //populate with users email, leave blank for guest
      clientId: getClientId(), //populate with the Anonymous cookie identifier that Google Analytics assigns to every single browser instance of any given web visitor
      userCRMId: generateUserId(), //populate with the user ID from the CRM
      redirectCount: '0', //count the number of times this redirects happened on this page
      navigationType: (window as any).performance.getEntriesByType(
        'navigation'
      )[0].type, //how the user navigated to this page of the site, should be populated with "Reload" (refreshing of the page), "Navigate" (typed into URL), "Back/Forward" (using browser arrows). "Other" (everything else)
      ...createBasicParams(),
      ecommerce: {
        detail: {
          products: [
            {
              name: title,
              id: (<HTMLInputElement>(
                document.querySelector('[data-product-id]')
              )).getAttribute('data-product-id'),
              price: '0',
              brand: siteName,
              category: category,
            },
          ],
        },
      },
    });

    const el = relatedProducts;
    if (relatedProducts) {
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !eventAdded) {
            let impressions: any = [];

            document
              .querySelectorAll('[data-related-slide]')
              .forEach((slide, key) => {
                impressions.push({
                  name: slide
                    .querySelector('[data-related-title]')
                    .getAttribute('data-related-title'),
                  id: slide.getAttribute('data-related-id'),
                  price: '0',
                  brand: siteName,
                  category: slide
                    .querySelector('[data-related-category]')
                    .getAttribute('data-related-category'),
                  position: key + 1,
                });
              });

            (window as any).dataLayer.push({ ecommerce: null });

            (window as any).dataLayer.push({
              pageType: 'pdp',
              pageCategory: category, //populate with the page category or pageType if not applicable
              pageTitle: document.title, //populate with page meta title
              pageName: `pdp: ${document.title}`, //populate with <pageType: pageTitle>
              pageBreadcrumb: `products > plp ${category} > pdp ${title}`, //populate with url breadcrumb
              hitPayLoad: '50', //The idea is that you can monitor if you are approaching the 8192 byte limit, since hits that equal or surpass that limit will not get sent to Google Analytics.
              pageCountryCode: 'US', //populate with the 2 character page country code, if only US then populate with US
              userEmailHashed: '', //populate with users hashed email, leave blank for guest
              userEmail: '', //populate with users email, leave blank for guest
              clientId: getClientId(), //populate with the Anonymous cookie identifier that Google Analytics assigns to every single browser instance of any given web visitor
              userCRMId: generateUserId(), //populate with the user ID from the CRM
              redirectCount: '0', //count the number of times this redirects happened on this page
              navigationType: (window as any).performance.getEntriesByType(
                'navigation'
              )[0].type, //how the user navigated to this page of the site, should be populated with "Reload" (refreshing of the page), "Navigate" (typed into URL), "Back/Forward" (using browser arrows). "Other" (everything else)
              ...createBasicParams(),
              ecommerce: {
                detail: {
                  products: [
                    {
                      name: title,
                      id: (<HTMLInputElement>(
                        document.querySelector('[data-product-id]')
                      )).getAttribute('data-product-id'),
                      price: '0',
                      brand: siteName,
                      category: category,
                    },
                  ],
                  impressions: impressions,
                },
              },
            });

            eventAdded = true;

            return;
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      observer.observe(el);
    }
  }

  // Contact Interaction
  if (window.location.href.includes('contact-us')) {
    let copySections = document.querySelectorAll('[data-scroll-copy]');

    copySections.forEach((copySection) => {
      copySection.querySelectorAll('a').forEach((copyLink) => {
        copyLink.addEventListener('click', () => {
          (window as any).dataLayer.push({
            event: 'contact', //populate the event exactly as written
            contactText: copyLink.textContent, //populate with the option selected
            contactIntent: copySection.getAttribute('data-scroll-title'), //populate with the contact top category
            contactLocation: '', //populate with the contact location
            userEmailHashed: '', //populate with users hashed email, leave blank for guest
            userEmail: '', //populate with users email, leave blank for guest
            componentName: 'Intro Content', //populate with the name of the component from the CMS
            pageUrl: copyLink.href, //populate with destination url
          });
        });
      });
    });
  }

  window.addEventListener('message', (event) => {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormSubmit'
    ) {
      let email = <HTMLInputElement>document.querySelector("[name='email']");
      let requestType = <HTMLSelectElement>(
        document.querySelector("[name='request_type']")
      );
      let city = <HTMLInputElement>document.querySelector("[name='city']");
      let state = <HTMLInputElement>document.querySelector("[name='state']");
      let profession = <HTMLSelectElement>(
        document.querySelector("[name='profession']")
      );

      if (window.location.href.includes('feedback-form')) {
        // Submit Feedback Form
        (window as any).dataLayer.push({
          event: 'feedbackFormSubmit',
          requestType: requestType
            ? requestType.value
            : (<HTMLSelectElement>(
                document.querySelector("[name='unifix_request_type']")
              )).value, //populate with the request type
          profession: profession.value, //populate with the entered profession
          email: email.value, //Populated with the user email
          emailHashed: hashValue(email.value), //Populated with the user hashed email
          userCity: city ? city.value : '', //Populated with the user city
          userState: state ? state.value : '', //Populated with the user state
          ...createBasicParams(),
        });
      } else if (
        window.location.href.includes('contact-us') ||
        window.location.href.includes('preference-center')
      ) {
        // Submit Newsletter Signup
        (window as any).dataLayer.push({
          event: 'newsletterSignUp', //populate the event exactly as written
          email: email.value, //populate with the email
          emailHashed: hashValue(email.value), //populate with the hashed email
          profession: profession
            ? profession.value
            : (<HTMLSelectElement>(
                document.querySelector("[name='architect_role']")
              )).value, //populate with the selected profession
          userCRMId: generateUserId(), //populate with the user ID from the CRM
          ...createBasicParams(),
        });
      } else if (window.location.href.includes('design-resource-center')) {
        let iframe = (<HTMLIFrameElement>(
          document.getElementById('hs-form-iframe-0')
        )).contentWindow;
        let iframeEmail: any = iframe.document.querySelector('[name="email"]');

        // Learn More Form
        (window as any).dataLayer.push({
          event: 'learnMoreFromSubmission', //populate the event exactly as written
          companyName: (<HTMLInputElement>(
            iframe.document.querySelector('[name="company"]')
          )).value, //populate with the company name entered
          userfirstName: (<HTMLInputElement>(
            iframe.document.querySelector('[name="firstname"]')
          )).value, //populate with the user first name entered
          userLastName: (<HTMLInputElement>(
            iframe.document.querySelector('[name="lastname"]')
          )).value, //populate with the user last name
          userEmailHashed: hashValue(<HTMLInputElement>iframeEmail.value), //populate with users hashed email, leave blank for guest
          userEmail: <HTMLInputElement>iframeEmail.value, //populate with users email, leave blank for guest
          componentName: 'Hubspot Form', //populate with the name of the component from the CMS
          ...createBasicParams(),
        });
      }
    }
  });
}

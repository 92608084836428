export default function onInit() {
  const tabs:NodeListOf<HTMLElement> = document.querySelectorAll('[data-tabs]');

  tabs.forEach((tab:HTMLElement) => {
    const tabButtons:NodeListOf<HTMLButtonElement> = tab.querySelectorAll('[data-tab]');
    const tabContents:NodeListOf<HTMLElement> = tab.querySelectorAll('[data-tab-content]');
    const firstActiveTab:HTMLButtonElement = tab?.querySelector('[data-tab-results]');

    if (firstActiveTab) {
      const firstActiveContent:HTMLElement = tab.querySelector(`[data-tab-content="${firstActiveTab.dataset.tab}"]`);
      tabContents.forEach((tabContent:HTMLElement) => tabContent.classList.remove('is-active'));
      firstActiveTab.classList.add('is-active');
      firstActiveContent.classList.add('is-active');
    } else {
      tabButtons[0].classList.add('is-active');
    }

    tabButtons.forEach((tabButton:HTMLButtonElement) => {
      tabButton.addEventListener('click', () => {
        const tabId = tabButton.dataset.tab;
        const connectedTab = tab.querySelector(`[data-tab-content="${tabId}"]`);

        tabButtons.forEach((button:HTMLButtonElement) => button.classList.remove('is-active'));
        tabContents.forEach((tabContent:HTMLElement) => tabContent.classList.remove('is-active'));

        tabButton.classList.add('is-active');
        connectedTab.classList.add('is-active');
      })
    })
  })
}

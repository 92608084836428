export default function onInit() {
  document.querySelectorAll('[data-form-label-placeholder]').forEach(node => {
    const input = node.querySelector('input, textarea, select');

    function setPlaceholderState() {
      const element = this.tagName === 'SELECT' ? this.options[this.selectedIndex] : this;
      const isFloating = element && element.value !== '';
      this.parentNode.classList[isFloating ? 'add' : 'remove']('is-floating');
    }

    if (input) {
      input.addEventListener('change', setPlaceholderState);
      input.addEventListener('input', setPlaceholderState);
      input.addEventListener('keydown', setPlaceholderState);

      setPlaceholderState.call(input);
    }

    node.setAttribute('data-form-label-placeholder', 'initialized');
  });
}

import { internationalDistributorsEvent } from './ga4-events';

const HEADER_OFFSET = 213;

function scrollTo(offset: number, behavior: ScrollBehavior = 'smooth') {
  window.scroll({
    top: offset,
    behavior: behavior,
  });
}

function onInit() {
  let contactPhones = document.querySelectorAll('[data-phone-number]');
  let contactEmails = document.querySelectorAll('[data-contact-email]');
  let regionDropdown = <HTMLSelectElement>(
    document.querySelector('#unifix-selector')
  );

  if (regionDropdown) {
    const unifixSelector = document.querySelector(
      '#unifix-selector'
    ) as HTMLInputElement;

    unifixSelector.addEventListener('change', (event) => {
      event.preventDefault();

      const baseUrl = window.location.href.split('#')[0];

      window.location.href = baseUrl + '#' + unifixSelector.value;

      let selector = document.getElementById(unifixSelector.value);
      if (selector) {
        const { offsetTop } = selector;
        const offset = offsetTop - HEADER_OFFSET;
        scrollTo(offset);
      }
    });
  }

  if (contactPhones.length && regionDropdown) {
    contactPhones.forEach((contactPhone) => {
      contactPhone.addEventListener('click', () => {
        let contactName = contactPhone.parentNode.querySelector(
          '[data-contact-name]'
        );
        internationalDistributorsEvent({
          name: 'internationalDistributors',
          country: regionDropdown.value
            ? regionDropdown.options[regionDropdown.selectedIndex].innerHTML
            : '',
          contactOption: 'call',
          textSelected: contactPhone.innerHTML,
          contactName: contactName
            ? contactName.getAttribute('data-contact-name')
            : '',
        });
      });
    });
  }

  if (contactEmails.length && regionDropdown) {
    contactEmails.forEach((contactEmail) => {
      contactEmail.addEventListener('click', () => {
        let contactName = contactEmail.parentNode.querySelector(
          '[data-contact-name]'
        );
        internationalDistributorsEvent({
          name: 'internationalDistributors',
          country: regionDropdown.value
            ? regionDropdown.options[regionDropdown.selectedIndex].innerHTML
            : '',
          contactOption: 'call',
          textSelected: contactEmail.innerHTML,
          contactName: contactName
            ? contactName.getAttribute('data-contact-name')
            : '',
        });
      });
    });
  }
}

export default onInit;

import MarkerClusterer from '@googlemaps/markerclustererplus';
import mapDarkStype from './map-dark-style';

let map: google.maps.Map;
let markers = new Array<google.maps.Marker>();
let markerClusterer;
let infoWindow: google.maps.InfoWindow;

interface MarkerData {
  lat: number;
  lng: number;
  title: string;
  type: string;
  address: string;
}
const DISPLAY_ALL_MARKERS = '0';
const mapAttrName = '[data-map-whoweare]';
const mapEl: HTMLElement = document.querySelector(mapAttrName);
const markerLocations: Array<any> =
  mapEl !== null ? JSON.parse(mapEl.dataset.mapWhoweare) : [];
const mapFiltersEls: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-maptype-id]'
);
const mapAllLocationsCta: HTMLElement = document.querySelector(
  '[data-maptype-all]'
);

const mapOptions: google.maps.MapOptions = {
  center: { lat: 38.503082, lng: -98.464536 },
  zoom: 4,
  disableDefaultUI: true,
  zoomControl: true,
  styles: mapDarkStype,
};

const openInfoWindow = (currMarker: google.maps.Marker) => {
  map.panTo(currMarker.getPosition());

  if (infoWindow) {
    infoWindow.close();
  }

  const content: string = `
    <div class="w-64 p-5">
        <h6 class="font-primary text-xl leading-7 font-bold mb-5">${
          currMarker.get('title') || ''
        }</h6>
        <address class="w-3/4 font-primary text-lg leading-8 font-normal not-italic">${
          currMarker.get('address') || ''
        }</address>
    </div>`;

  infoWindow = new google.maps.InfoWindow({
    content,
  });

  infoWindow.open(map, currMarker);
};

const getMarkerData = (item: any): MarkerData => {
  const { address } = item;
  const location: MarkerData = {
    lat: +address.lat,
    lng: +address.lng,
    title: item.name,
    address: address.address,
    type: item.type,
  };
  return location;
};

const clearMarkerClusterer = () => {
  markerClusterer.setMap(null);
  markerClusterer = null;
}

const clearMarkerOnMap = () => {
  markers.forEach((marker: google.maps.Marker) => {
    marker.setMap(null);
    marker.setVisible(false);
  });
  markers = [];
};
const createMarker = (
  position: MarkerData,
  index: number
): google.maps.Marker => {
  const isActive = index === 0;
  const markerSize = { width: 28, height: 40 };

  const defaultMarkerImage = {
    url: '/dist/img/icons/map-marker.svg',
    scaledSize: new google.maps.Size(markerSize.width, markerSize.height),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(markerSize.width / 2, markerSize.height),
  };

  const activeMarkerImage = {
    ...defaultMarkerImage,
    url: '/dist/img/icons/map-marker-active.svg',
  };

  const setMarkerDefaultIcons = (thisMarker: google.maps.Marker) => {
    markers.forEach((currMarker: google.maps.Marker) => {
      currMarker.setIcon(defaultMarkerImage);
    });

    thisMarker.setIcon(activeMarkerImage);
  };

  const marker = new google.maps.Marker({
    draggable: false,
    map,
    icon: isActive ? activeMarkerImage : defaultMarkerImage,
    position,
  });

  marker.set('title', position.title);
  marker.set('address', position.address);

  google.maps.event.addListener(marker, 'click', () => {
    openInfoWindow(marker);
    setMarkerDefaultIcons(marker);
  });

  return marker;
};

const initMarkers = () => {
  if (markerLocations) {
    markerLocations.forEach((item: any, index: number) => {
      const location = getMarkerData(item);
      const markerCreated = createMarker(location, index);
      markers.push(markerCreated);
    });
  }
};

const inactiveFilterItems = () => {
  mapAllLocationsCta.classList.remove('map-type-button-active');
  mapAllLocationsCta.classList.add('map-type-button');

  mapFiltersEls.forEach((el: HTMLElement) => {
    el.classList.remove('map-type-button-active');
    el.classList.add('map-type-button');
  });
};

const filterMarkers = (filterId: string) => {
  if (filterId === DISPLAY_ALL_MARKERS) {
    clearMarkerOnMap();
    inactiveFilterItems();
    initMarkers();
    clearMarkerClusterer();
    initMarkerClustered();
    return;
  }

  const arrayFiltered = new Array<google.maps.Marker>();
  markerLocations.forEach((el: any) => {
    if (el.type.includes(filterId)) {
      arrayFiltered.push(el);
    }
  });

  if (arrayFiltered.length > 0) {
    clearMarkerOnMap();

    arrayFiltered.forEach((item: any, index: number) => {
      const location = getMarkerData(item);
      const markerCreated = createMarker(location, index);
      markers.push(markerCreated);
    });

    clearMarkerClusterer();
    initMarkerClustered();
  }
};

const initFiltering = () => {
  mapFiltersEls.forEach((el: HTMLElement) => {
    el.addEventListener('click', () => {
      inactiveFilterItems();
      el.classList.add('map-type-button-active');

      const id = el.dataset.maptypeId;
      filterMarkers(id);
    });
  });

  mapAllLocationsCta.addEventListener('click', (e: MouseEvent) => {
    e.preventDefault();
    filterMarkers(DISPLAY_ALL_MARKERS);

    mapAllLocationsCta.classList.remove('map-type-button');
    mapAllLocationsCta.classList.add('map-type-button-active');
  });
};

const initMarkerClustered = () => {
  markerClusterer = new MarkerClusterer(map, markers, {
    maxZoom: 10,
    gridSize: 40,
    styles: [
      {
        width: 24,
        height: 24,
        className: 'custom-clustericon-1',
      },
      {
        width: 28,
        height: 28,
        className: 'custom-clustericon-2',
      },
      {
        width: 32,
        height: 32,
        className: 'custom-clustericon-3',
      },
    ],
    clusterClass: 'custom-clustericon',
  });
};

const initWhoWeAreMap = () => {
  if (!mapEl) {
    return;
  }
  map = new google.maps.Map(
    document.querySelector(mapAttrName) as HTMLElement,
    mapOptions
  );

  initMarkers();
  initFiltering();
  initMarkerClustered();
};

export default initWhoWeAreMap;

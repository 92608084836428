import * as Flickity from "flickity-as-nav-for";

const isMobileDeviceFn = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const MOBILE_DEVICE_WIDTH = 375;
const isMobileScreenFn = () => {
  return screen.width === MOBILE_DEVICE_WIDTH;
};

function setObservers(container: any, elements: any) {
  const observerOpts = {
    root: container,
    rootMargin: "0px",
    threshold: 0.9,
  };

  const observer = new window.IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      const childLinks = entry.target.querySelectorAll("a");

      if (entry.isIntersecting) {
        entry.target.classList.remove("fade");
        entry.target.removeAttribute("tabindex");

        if (childLinks) {
          childLinks.forEach((link: any) => {
            link.removeAttribute("tabindex");
          });
        }
      } else {
        entry.target.classList.add("fade");
        entry.target.setAttribute("tabindex", "-1");

        if (childLinks) {
          childLinks.forEach((link: any) => {
            link.setAttribute("tabindex", "-1");
          });
        }
      }
    });
  }, observerOpts);

  elements.forEach((element: any) => {
    observer.observe(element);
  });
}

function onFlickityInit(flkty: any) {
  window.addEventListener("load", () => {
    const { resize } = Flickity.prototype;

    Flickity.prototype.createResizeClass = function flktyCreateClass() {
      this.element.classList.add("flickity-resize");
    };

    Flickity.createMethods.push("_createResizeClass");

    Flickity.prototype.resize = function flktyResize() {
      this.element.classList.remove("flickity-resize");
      resize.call(this);
      this.element.classList.add("flickity-resize");
    };

    flkty.resize();
  });
}

export default function onInit() {
  const carousels = document.querySelectorAll("[data-carousel]");

  carousels.forEach((component) => {
    const carousel = component.querySelector<HTMLElement>(
      "[data-carousel-inner]"
    );
    const carouselButtons: NodeListOf<HTMLButtonElement> = component.querySelectorAll(
      "[data-carousel-button]"
    );
    const carouselSlides = component.querySelectorAll("[data-carousel-slide]")
    const productCells = document.querySelectorAll(".ProductCell");
    const productCellsHidden = document.querySelectorAll(".ProductCell--hidden");
    const productCellsLarge = document.querySelectorAll(".ProductCell--large");
    const productCellsXL = document.querySelectorAll(".ProductCell--xl");
    const productCellButton = document.querySelector(".ProductCell-btn");

    let carouselOptions = {
      cellAlign: "left",
      prevNextButtons: false,
      freeScroll: true,
      contain: true,
      groupCells: true,
    };

    if (carousel && carousel.dataset.carouselOptions !== undefined) {
      const parsed = JSON.parse(carousel.dataset.carouselOptions);

      carouselOptions = {
        ...carouselOptions,
        ...parsed,
      };
    }

    const isMobileDevice = isMobileDeviceFn();
    const isMobileScreen = isMobileScreenFn();

    if (
      (isMobileDevice || isMobileScreen) &&
      carousel.dataset.carouselMobile !== undefined
    ) {
      const parsed = JSON.parse(carousel.dataset.carouselMobile);
      carouselOptions = {
        ...carouselOptions,
        ...parsed,
      };
    }

    const flkty = new Flickity(carousel, carouselOptions);
    onFlickityInit(flkty);

    if (
      productCellsHidden &&
      productCellButton &&
      carousel.classList.contains("Carousel-compare")
    ) {
      productCellButton.addEventListener("click", function () {
        const expanded = carousel.getAttribute("data-expanded");
        if (expanded === "1") {
          productCellsHidden.forEach((cell: any) => {
            cell.classList.add("hidden");
          });
          productCellsXL.forEach((cell: any) => {
            cell.classList.remove("h-56", "md:h-66");
            cell.classList.add("md:h-24");
          });
          document
            .querySelectorAll(".ProductCell-large")
            .forEach((cell: any) => cell.classList.add("hidden"));
          document
            .querySelectorAll(".ProductCell-preview")
            .forEach((cell: any) => cell.classList.remove("hidden"));
          productCellButton.innerHTML =
            '<span>More Specifications</span><svg width="20" height="20" class="ml-2" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 5.22217L8 11.2222L2 5.22217" stroke="black" stroke-width="2"></path></svg>';
        }
        if (expanded === "0") {
          productCellsHidden.forEach((cell: any) => {
            cell.classList.remove("hidden");
          });
          productCellsXL.forEach((cell: any) => {
            cell.classList.remove("md:h-24");
            cell.classList.add("h-56", "md:h-66");
          });
          productCells.forEach((cell: any) => {
            cell.classList.remove("h-17", "h-13", "h-20");
          });
          document
            .querySelectorAll(".ProductCell-preview")
            .forEach((cell: any) => cell.classList.add("hidden"));
          document
            .querySelectorAll(".ProductCell-large")
            .forEach((cell: any) => cell.classList.remove("hidden"));
          productCellButton.innerHTML =
            '<span>Fewer Specifications</span><svg width="20" height="20" class="ml-2" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 11.5L8 5.5L2 11.5" stroke="black" stroke-width="2"></path></svg>';
        }
        carousel.setAttribute("data-expanded", expanded === "0" ? "1" : "0");
        flkty.resize();
      });
    }

    function disabledHandler() {
      const FLK_LEN = flkty.slides.length;
      const NUM_OF_ITEMS = 1;

      const leftButton = carouselButtons[0];
      const rightButton = carouselButtons[1];

      const disableLeftButton = flkty.selectedIndex === 0;
      const disableRightButton = flkty.selectedIndex === FLK_LEN - NUM_OF_ITEMS;

      leftButton.disabled = disableLeftButton;
      rightButton.disabled = disableRightButton;

      if (disableLeftButton && disableRightButton) {
        leftButton.style.display = "none";
        rightButton.style.display = "none";
      } else {
        leftButton.style.display = "";
        rightButton.style.display = "";
      }
    }

    function prevNextHandler(e: any) {
      const btnType = e.target.dataset.carouselButton;
      if (btnType === "prev") {
        flkty.previous();
      } else if (btnType === "next") {
        flkty.next();
      }
    }

    carouselButtons.forEach((button: HTMLButtonElement) => {
      button.addEventListener("click", prevNextHandler);
    });

    if (carouselButtons.length) {
      flkty.on("select", () => {
        disabledHandler();
      });
    }

    setObservers(carousel, carouselSlides);
  });
}

export default function onInit() {
  let resourceId = document.querySelector("[data-resource-id]") as HTMLElement;
  if (resourceId) {
    let url = `/actions/appmodule/fsa/download-zip?resource-id=${resourceId.dataset.resourceId}&`;
    let newUrl = "";
    const downloadButton = document.querySelector("#downloadAllFiles");

    if (downloadButton) {
      downloadButton.addEventListener("click", () => {
        document
          .querySelectorAll("[data-widen-id]")
          .forEach((resource: HTMLElement) => {
            url = `${url}ids[]=${encodeURIComponent(
              resource.dataset.widenId
            )}&`;
            newUrl = url.slice(0, -1);
          });
        window.location.href = newUrl;
      });
    }
  }
}

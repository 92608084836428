const HEADER_OFFSET = 213;

function scrollTo(offset: number, behavior: ScrollBehavior = 'smooth') {
  window.scroll({
    top: offset,
    behavior: behavior,
  });
}

function initAutoScroll() {
  if (location.hash) {
    const idTarget = location.hash.split('#')[1];
    const target = document.getElementById(idTarget);
    if (target) {
      const { offsetTop } = target;
      const offset = offsetTop - HEADER_OFFSET;
      scrollTo(offset);
      console.log('scrolled onInit', offset);
    }
  }
}

function clickHandler(e: any) {
  e.preventDefault();

  const href = e.target.getAttribute('data-scroll-anchor');
  const target = document.getElementById(href);

  if (target !== null) {
    const { offsetTop } = document.getElementById(href);
    const offset = offsetTop - HEADER_OFFSET;
    scrollTo(offset);
  }
}

export default function onInit() {
  const scrollSections = document.querySelectorAll<HTMLElement>(
    '[data-scroll-section]'
  );
  const scrollAnchors = document.querySelectorAll('[data-scroll-anchor]');
  const backToTopBtn = document.querySelector('[data-back-top]');

  if (scrollAnchors && scrollSections) {
    const observerConfig = {
      rootMargin: '-35% 0px -45% 0px',
      threshold: 0,
    };

    const scrollSectionObserver = new window.IntersectionObserver((entries) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        // the section that contains the id from the CMS
        const el = (entry.target as HTMLElement).previousElementSibling;

        if (entry.isIntersecting && el.id) {
          const scrollAnchor = document.querySelector(
            `[data-scroll-anchor="${el.id}"]`
          );

          if (scrollAnchor !== null) {
            scrollAnchors.forEach((anchor) => {
              anchor.classList.remove('is-active');
            });

            scrollAnchor.classList.add('is-active');
          }
        }
      });
    }, observerConfig);

    scrollSections.forEach((section: HTMLElement) => {
      // next sibling (the section that contains the content)
      scrollSectionObserver.observe(section.nextElementSibling);
    });

    scrollAnchors.forEach((link) => {
      link.addEventListener('click', clickHandler);
    });
  }

  if (backToTopBtn) {
    backToTopBtn.addEventListener('click', () => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    });
  }

  setTimeout(initAutoScroll, 500);
}

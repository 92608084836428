const pdfLinks = document.querySelectorAll<HTMLAnchorElement>('[data-pdf]');

const downloadPdf = (url: string, productName: string) => {
  // (window as any).dataLayer.push({
  //   event: 'contentdownload',
  //   pdfFileName: productName,
  // });
  if (!window.location.href.includes('kioskmode=true')) {
    window.open(url, '_blank');
  }
};

export default () => {
  if (pdfLinks) {
    pdfLinks.forEach((link: HTMLAnchorElement) => {
      link.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();

        const { href, title } = link;
        downloadPdf(href, title);
      });
    });
  }
};

import {
  submittalBuilderDownload,
  submittalBuilderFunnel,
  submittalBuilderTypeSelection,
  submittalBuilderDocSelectionEvent
} from './ga4-events';

interface ResourceData {
  img: string;
  title: string;
  type: string;
  name: string;
}

class SubmittalBuilder {
  public products: NodeListOf<HTMLElement>;

  public productCopy: NodeListOf<HTMLElement>;

  public categories: NodeListOf<HTMLElement>;

  public resources: NodeListOf<HTMLElement>;

  public cart: HTMLElement;

  public resourcesContainer: HTMLElement;

  public cartResourcesContainer: HTMLElement;

  public cartLinkStep1: NodeListOf<HTMLElement>;

  public cartLinkOtherSteps: HTMLElement;

  public cartTrigger: NodeListOf<HTMLElement>;

  public clearCartTrigger: HTMLElement;

  public productsContainer: HTMLElement;

  public count: NodeListOf<HTMLElement>;

  public disclaimer: HTMLElement;

  public disclaimerBtn: HTMLElement;

  public combinedDownloadBtn: HTMLButtonElement;

  public singleDownloadBtn: HTMLButtonElement;

  public textSearch: HTMLInputElement;

  public selectSearch: HTMLElement;

  public inputSelectedProducts: HTMLInputElement;

  public addCopy: string;

  public removeCopy: string;

  constructor(node: HTMLElement) {
    this.products = node.querySelectorAll('[data-product]');
    this.productCopy = node.querySelectorAll('[data-product-copy]');
    this.categories = node.querySelectorAll('[data-category]');
    this.resources = node.querySelectorAll('[data-resource]');
    this.resourcesContainer = node.querySelector('[data-resources-container]');
    this.cart = node.querySelector('[data-cart]');
    this.cartResourcesContainer = node.querySelector('[data-cart-resources]');
    this.cartLinkStep1 = node.querySelectorAll('[data-cart-link="true"]');
    this.cartLinkOtherSteps = node.querySelector('[data-cart-link="false"]');
    this.cartTrigger = node.querySelectorAll('[data-cart-trigger]');
    this.clearCartTrigger = node.querySelector('[data-clear-cart]');
    this.productsContainer = node.querySelector('[data-products-container]');
    this.count = node.querySelectorAll('[data-count]');
    this.disclaimer = node.querySelector('[data-disclaimer]');
    this.disclaimerBtn = node.querySelector('[data-close-disclaimer]');
    this.combinedDownloadBtn = node.querySelector('[data-combined-download]');
    this.singleDownloadBtn = node.querySelector('[data-single-download]');
    this.textSearch = node.querySelector('[data-text-filter]');
    this.selectSearch = node.querySelector('[data-select-filter]');
    this.inputSelectedProducts = node.querySelector(
      '[name="selectedProducts"]'
    );
    this.addCopy = '<span>+ Add</span>';
    this.removeCopy = '<span>– Remove</span>';

    if (this.products) {
      this.products.forEach((product) =>
        product.addEventListener('click', this.showProductResources.bind(this))
      );
    }

    if (this.resources) {
      this.resources.forEach((resource) =>
        resource.addEventListener('click', this.addResource.bind(this))
      );
    }

    if (this.cartTrigger) {
      this.cartTrigger.forEach((trigger) =>
        trigger.addEventListener('click', this.openCart.bind(this))
      );
    }

    if (this.clearCartTrigger) {
      this.clearCartTrigger.addEventListener(
        'click',
        this.clearCart.bind(this)
      );
    }

    if (this.disclaimerBtn) {
      this.disclaimerBtn.addEventListener(
        'click',
        this.closeDisclaimer.bind(this)
      );
    }

    if (this.textSearch) {
      this.textSearch.addEventListener('keyup', this.productSearch.bind(this));
      this.textSearch.addEventListener(
        'blur',
        ({ currentTarget }: KeyboardEvent) => {
          const searchInputEl = <HTMLInputElement>currentTarget;
          (window as any).dataLayer.push({
            event: 'submittalBuilderSearch',
            searchQuery: searchInputEl.value,
          });
        }
      );
    }

    if (this.selectSearch) {
      this.selectSearch.addEventListener(
        'change',
        this.categorySearch.bind(this)
      );
    }

    this.onInit();
  }

  onInit() {
    const localResources = JSON.parse(
      localStorage.getItem('resources') || '[]'
    );
    const disclaimer = localStorage.getItem('closeDisclaimer');
    let url = '/actions/appmodule/submittal-builder/download-zip?';
    let submissionId: string = '';

    if (this.singleDownloadBtn) {
      submissionId = this.singleDownloadBtn.getAttribute(
        'data-single-download'
      );
    }

    if (!localResources.length) {
      if (this.cartLinkOtherSteps) {
        window.location.href = '/submittal-builder';
      }

      this.cartLinkStep1.forEach((link: HTMLAnchorElement) => {
        link.classList.add('is-disabled');
      });
    }

    localResources.forEach((data: any) => {
      if (this.singleDownloadBtn) {
        url = `${url}ids[]=${data.id}&`;
        const newUrl = url.slice(0, -1);

        this.singleDownloadBtn.setAttribute(
          'data-url',
          `${newUrl}&submission=${submissionId}`
        );
        this.combinedDownloadBtn.setAttribute(
          'data-url',
          `${newUrl}&submission=${submissionId}&concatenate=1`
        );

        //this.singleDownloadBtn.href = `${newUrl}&submission=${submissionId}`;
        //this.combinedDownloadBtn.href = `${newUrl}&submission=${submissionId}&concatenate=1`;
      }

      if (this.productsContainer) {
        const productResource: HTMLButtonElement = this.productsContainer.querySelector(
          `[data-resource-title='${data.name}']`
        );

        productResource.parentElement.classList.add('is-added');
        productResource.classList.add('is-added');
        productResource.innerHTML = this.removeCopy;
      }

      this.cartResource(data);
    });

    if (this.count) {
      if (localResources.length) {
        this.count.forEach((element: HTMLElement) => {
          const el = element;
          el.innerHTML = localResources.length;
        });
      }
    }

    if (disclaimer) {
      if (this.disclaimer) {
        this.disclaimer.remove();
      }
    }

    this.setSelectedProducts(localResources);
    if (window.location.search.includes('step=3')) {
      this.initDownloadPdfs();
      if (localResources.length) {
        this.clearCart();
      }
    }
    document.querySelectorAll('[data-step]').forEach((step: any) => {
      step.addEventListener('click', () => {
        submittalBuilderFunnel({
          name: 'submittalBuilder',
          step: step.getAttribute('data-step'),
          stepText: step.textContent,
        });
      });
    });
  }

  initDownloadPdfs() {
    const goToUrl = (products: string, url: string) => {
      // (window as any).dataLayer.push({
      //   event: 'contentdownload',
      //   pdfFileName: products,
      // });

      submittalBuilderDownload({
        name: 'submittalBuilderDownload',
        downloadSelection: products,
      });

      submittalBuilderDownload({
        name: 'submittalBuilderDownload',
        downloadSelection: products,
      });

      window.location.href = url;
    };

    if (this.singleDownloadBtn) {
      this.singleDownloadBtn.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();
        const { products, url } = this.singleDownloadBtn.dataset;
        goToUrl(products, url);
      });

      this.combinedDownloadBtn.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault();
        const { products, url } = this.combinedDownloadBtn.dataset;
        goToUrl(products, url);
      });
    }
  }

  showProductResources(e: MouseEvent) {
    const clonedResources = (<HTMLElement>(
      e.currentTarget
    )).nextSibling.cloneNode(true);

    if (!(<HTMLElement>e.currentTarget).classList.contains('is-active')) {
      this.products.forEach((node) => {
        node.classList.remove('is-active');
      });

      const resources = this.resourcesContainer.querySelector(
        '[data-resources]'
      );

      if (resources) {
        resources.remove();
      }

      if (this.resourcesContainer.classList.contains('is-hidden')) {
        this.resourcesContainer.style.display = '';
      }

      this.resourcesContainer.appendChild(clonedResources);

      const allResources = this.resourcesContainer.querySelectorAll(
        '[data-resource]'
      );

      allResources.forEach((resource) =>
        resource.addEventListener('click', this.addResource.bind(this))
      );

      // GA4 Migration - submittalBuilderDocSelection event
      let docEl = <HTMLElement>e.currentTarget;
      submittalBuilderDocSelectionEvent({
        doc: docEl.dataset.doc,
        brand: docEl.dataset.brand
      });

    } else {
      this.resourcesContainer.querySelector('[data-resources]').remove();
    }

    (<HTMLElement>e.currentTarget).classList.toggle('is-active');
  }

  addResource(e: any) {
    const localResources: ResourceData[] = JSON.parse(
      localStorage.getItem('resources') || '[]'
    );
    const resourceData: ResourceData = JSON.parse(
      e.currentTarget.dataset.resource
    );

    e.currentTarget.classList.toggle('is-added');
    e.currentTarget.parentNode.classList.toggle('is-added');

    if (e.currentTarget.classList.contains('is-added')) {
      let parent = e.currentTarget.parentNode;
      submittalBuilderTypeSelection({
        name: 'submittalBuilderTypeSelection',
        docSelected: e.currentTarget.getAttribute('data-resource-title'),
        brandSelected: e.currentTarget.getAttribute('data-brand'),
        typeSelected: e.currentTarget.previousElementSibling.textContent,
      });

      e.currentTarget.innerHTML = this.removeCopy;

      const productsContainerSelector = `[data-resource-title='${resourceData.name}']`;
      const productsContainerResources = this.productsContainer.querySelector(
        productsContainerSelector
      );

      productsContainerResources.classList.add('is-added');
      productsContainerResources.innerHTML = this.removeCopy;

      localResources.push(resourceData);
      localStorage.setItem('resources', JSON.stringify(localResources));

      this.cartResource(resourceData);

      if (this.count) {
        this.count.forEach((element) => {
          const el = element;
          el.innerHTML = localResources.length.toString();
        });
      }

      this.cartLinkStep1.forEach((link: HTMLElement) => {
        link.classList.remove('is-disabled');
      });
      this.setSelectedProducts(localResources);
    } else {
      e.currentTarget.innerHTML = this.addCopy;

      const singleResource = localResources.filter((data: ResourceData) => {
        if (decodeURIComponent(resourceData.name) === data.name) {
          return data;
        }

        return false;
      });

      const filteredLocalResources = localResources.filter((data) => {
        return decodeURIComponent(resourceData.name) !== data.name;
      });

      localStorage.setItem('resources', JSON.stringify(filteredLocalResources));

      singleResource.forEach((data) => {
        this.removeResource(data.name);
      });

      if (this.count) {
        this.count.forEach((element) => {
          const el = element;
          el.innerHTML = filteredLocalResources.length.toString();
        });
      }
      this.setSelectedProducts(filteredLocalResources);
    }
  }

  removeResource(title: string) {
    if (!title) {
      return;
    }

    const localResources: ResourceData[] = JSON.parse(
      localStorage.getItem('resources') || '[]'
    );
    const cartResource = this.cartResourcesContainer.querySelector(
      `[data-cart-resource='${title}']`
    );

    if (
      !cartResource.nextElementSibling &&
      !cartResource.previousElementSibling
    ) {
      cartResource.closest('.Cart-resource-container').remove();
    }

    if (localResources.length <= 0) {
      if (this.cartLinkOtherSteps) {
        window.location.href = '/submittal-builder';
      }

      this.cartLinkStep1.forEach((link: HTMLAnchorElement) => {
        link.classList.add('is-disabled');
      });
    }

    cartResource.remove();

    if (this.productsContainer) {
      const productResource = this.productsContainer.querySelector(
        `[data-resource-title='${title}']`
      );
      const resourcesContainerResource = this.resourcesContainer.querySelector(
        `[data-resource-title='${title}']`
      );

      if (productResource) {
        productResource.parentElement.classList.remove('is-added');
        productResource.classList.remove('is-added');
        productResource.innerHTML = this.addCopy;
      }

      if (resourcesContainerResource) {
        resourcesContainerResource.classList.remove('is-added');
        resourcesContainerResource.innerHTML = this.addCopy;
      }
    }
  }

  cartResource(data: ResourceData) {
    const resource = document.createElement('div');
    const resourceTitle = document.createElement('h3');
    const resourceImg = document.createElement('img');
    const resourceButton = document.createElement('button');
    const dataType = data.type.replace(/\s+/g, '-').toLowerCase();
    const resourceGroup = document.querySelector(
      `[data-resource-type='${dataType}']`
    );

    resource.classList.add('SubmittalBuilder-resource');
    resource.setAttribute('data-cart-resource', `${data.name}`);

    resourceTitle.innerHTML = data.title;
    resourceImg.src = data.img;

    resourceButton.classList.add('btn');
    resourceButton.classList.add('btn-primary');
    resourceButton.classList.add('is-added');
    resourceButton.setAttribute('data-resource', `{"name": "${data.name}"}`);
    resourceButton.innerHTML = this.removeCopy;

    resource.appendChild(resourceImg);
    resource.appendChild(resourceTitle);
    resource.appendChild(resourceButton);

    resourceButton.addEventListener('click', (e) => {
      e.preventDefault();
      this.addResource(e);
    });

    if (resourceGroup) {
      resourceGroup.appendChild(resource);
    } else {
      const container = document.createElement('div');
      const resourcesContainer = document.createElement('div');
      const resourceType = document.createElement('p');

      container.classList.add('Cart-resource-container');
      resourcesContainer.setAttribute('data-resource-type', `${dataType}`);
      resourceType.innerHTML = data.type;

      container.appendChild(resourceType);
      container.appendChild(resourcesContainer);
      resourcesContainer.appendChild(resource);

      this.cartResourcesContainer.appendChild(container);
    }
  }

  openCart() {
    this.cart.classList.toggle('is-open');
    this.cart.parentElement.classList.toggle('is-open');
  }

  clearCart() {
    const cartResource = this.cartResourcesContainer.querySelectorAll(
      '.Cart-resource-container'
    );

    cartResource.forEach((element) => {
      element.remove();
    });

    this.cartLinkStep1.forEach((link: HTMLAnchorElement) => {
      link.classList.add('is-disabled');
    });

    localStorage.setItem('resources', '[]');
    this.setSelectedProducts();

    if (this.cartLinkOtherSteps) {
      window.location.href = '/submittal-builder';
    }

    if (this.productsContainer) {
      const productResource = this.productsContainer.querySelectorAll(
        '[data-resource]'
      );

      productResource.forEach((element) => {
        const el = element;

        el.parentElement.classList.remove('is-added');
        el.classList.remove('is-added');
        el.innerHTML = this.addCopy;
      });
    }

    if (this.count) {
      this.count.forEach((element) => {
        const el = element;
        el.innerHTML = '0';
      });
    }
  }

  closeDisclaimer() {
    this.disclaimer.remove();

    localStorage.setItem('closeDisclaimer', 'true');
  }

  productSearch(e: any) {
    const inputValue = e.currentTarget.value.toUpperCase();
    const terms = inputValue.trim().split(' ');
    let items: number[] = [];

    this.productCopy.forEach((element, key) => {
      if(terms.length) {
        terms.forEach((text: string) => {
          if(element.textContent.toUpperCase().indexOf(text) > -1)
            items.push(key);
        })
      }
    });

    // console.log(items);

    this.productCopy.forEach((element, key) => {
      element.parentElement.style.display = 'none';
      if (element.parentElement.classList.contains('is-active')) {
        this.resourcesContainer.style.display = 'none';
        this.resourcesContainer.classList.add('is-hidden');
      }

      if(items.length) {
        items.forEach((item) => {
          if(key == item) {
            element.parentElement.style.display = '';
            // console.log(element.textContent);
            if (element.parentElement.classList.contains('is-active')) {
              this.resourcesContainer.style.display = '';
              this.resourcesContainer.classList.remove('is-hidden');
            }
          }
        })
      }
    });
  }

  categorySearch(e: any) {
    const inputValue = e.currentTarget.value;

    this.categories.forEach((element) => {
      const category = element.dataset.category;
      const secondaryCategory = element.dataset.categorySecondary.length
        ? JSON.parse(element.dataset.categorySecondary)
        : [];

      if (
        category === inputValue ||
        secondaryCategory.includes(inputValue) ||
        inputValue === 'Search'
      ) {
        element.parentElement.style.display = '';

        if (element.parentElement.classList.contains('is-active')) {
          this.resourcesContainer.style.display = '';
          this.resourcesContainer.classList.remove('is-hidden');
        }
      } else {
        element.parentElement.style.display = 'none';

        if (element.parentElement.classList.contains('is-active')) {
          this.resourcesContainer.style.display = 'none';
          this.resourcesContainer.classList.add('is-hidden');
        }
      }
    });
  }

  setSelectedProducts(arrayToMap: any = []) {
    let str: string = '';
    str = arrayToMap.map((c: any) => c.name).join(';');

    if (str.length > 0) {
      str = `;${str}`;
    }

    if (this.inputSelectedProducts) {
      this.inputSelectedProducts.value = str;
    }

    if (this.singleDownloadBtn && str !== '') {
      this.singleDownloadBtn.setAttribute('data-products', str);
    }

    if (this.combinedDownloadBtn && str !== '') {
      this.combinedDownloadBtn.setAttribute('data-products', str);
    }

    return str;
  }
}

export default function onInit() {
  const submittalBuilder: HTMLElement = document.querySelector(
    '[data-submittal-builder]'
  );

  if (submittalBuilder) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const builder = new SubmittalBuilder(submittalBuilder);
  }
}

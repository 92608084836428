import { materialsCalculatorEvent, materialsDownloadEvent } from './ga4-events';

interface Materials {
  sheet8?: number;
  sheet10?: number;
  sheet12?: number;
  compoundJoint?: number;
  compoundLite?: number;
  compoundQuick?: number;
  tape?: number;
  nails?: number;
  screws?: number;
}

interface Product {
  title: string;
  image: string;
}

interface Dimensions {
  length: number;
  width: number;
  height: number;
  ceiling: boolean;
}

const params = new URL(document.location.href).searchParams;

// const elProductData:HTMLElement = document.querySelector('[data-calculator-data]');
const elCalculatorForm: HTMLElement = document.querySelector(
  '[data-calculator-form]'
);
const elLength: HTMLInputElement = elCalculatorForm
  ? elCalculatorForm.querySelector('[data-calculator-length]')
  : null;
const elWidth: HTMLInputElement = elCalculatorForm
  ? elCalculatorForm.querySelector('[data-calculator-width]')
  : null;
const elHeight: HTMLInputElement = elCalculatorForm
  ? elCalculatorForm.querySelector('[data-calculator-height]')
  : null;
const elCeiling: HTMLInputElement = elCalculatorForm
  ? elCalculatorForm.querySelector('[data-calculator-ceiling]')
  : null;
const elButton: HTMLButtonElement = elCalculatorForm
  ? elCalculatorForm.querySelector('[data-calculator-button]')
  : null;

const elsCalculatorResults: NodeListOf<HTMLElement> = document.querySelectorAll(
  '[data-calculator-results]'
);
// const elsDrywallImg:NodeListOf<HTMLImageElement> = document.querySelectorAll('[data-drywall-img]');
// const elsDrywallTitle:NodeListOf<HTMLSpanElement> = document.querySelectorAll('[data-drywall-title]');
// const elCompoundImg:HTMLImageElement = document.querySelector('[data-compound-img]');
// const elCompoundTitle:HTMLImageElement = document.querySelector('[data-compound-title]');
// const elCompoundLiteImg:HTMLImageElement = document.querySelector('[data-compound-lite-img]');
// const elCompoundLiteTitle:HTMLImageElement = document.querySelector('[data-compound-lite-title]');
// const elCompoundQuickImg:HTMLImageElement = document.querySelector('[data-compound-quick-img]');
// const elCompoundQuickTitle:HTMLImageElement = document.querySelector('[data-compound-quick-title]');
// const elTapeImg:HTMLImageElement = document.querySelector('[data-tape-img]');
// const elTapeTitle:HTMLImageElement = document.querySelector('[data-tape-title]');
const elPanelsShort: HTMLElement = document.querySelector(
  '[data-panels-short]'
);
const elPanelsMedium: HTMLElement = document.querySelector(
  '[data-panels-medium]'
);
const elPanelsWide: HTMLElement = document.querySelector('[data-panels-wide]');
const elCompound: HTMLElement = document.querySelector('[data-compound]');
const elCompoundLite: HTMLElement = document.querySelector(
  '[data-compound-lite]'
);
const elCompoundQuick: HTMLElement = document.querySelector(
  '[data-compound-quick]'
);
const elTape: HTMLElement = document.querySelector('[data-tape]');
const elScrews: HTMLElement = document.querySelector('[data-screws]');
const elNails: HTMLElement = document.querySelector('[data-nails]');

const elDownload: HTMLAnchorElement = document.querySelector(
  '[data-calculator-download]'
);
const elPrint: HTMLButtonElement = document.querySelector(
  '[data-calculator-print]'
);
const elEmail: HTMLAnchorElement = document.querySelector(
  '[data-calculator-email]'
);

function calcMaterials(sqft: number) {
  const materials: Materials = {};

  materials.sheet8 = Math.ceil(sqft / 32);
  materials.sheet10 = Math.ceil(sqft / 40);
  materials.sheet12 = Math.ceil(sqft / 48);
  materials.compoundJoint = Math.ceil((sqft / 1000) * 140);
  materials.compoundLite = Math.ceil((sqft / 1000) * 9);
  materials.compoundQuick = Math.ceil((sqft / 1000) * 55);
  materials.tape = Math.ceil((sqft / 1000) * 350);
  materials.nails = Math.ceil((sqft / 1000) * 1344);
  materials.screws = Math.ceil((sqft / 1000) * 768);

  return materials;
}

function calcSquareFootage(dimensions: Dimensions) {
  const { length, width, height, ceiling } = dimensions;
  if (ceiling) {
    return 2 * (length + width) * height + length * width;
  }

  return 2 * (length + width) * height;
}

function calculate() {
  const length: number = Math.ceil(Number(elLength.value.replace(/,/g, '')));
  const width: number = Math.ceil(Number(elWidth.value.replace(/,/g, '')));
  const height: number = Math.ceil(Number(elHeight.value.replace(/,/g, '')));
  const ceiling: boolean = elCeiling.checked;

  const dimensions = { length, width, height, ceiling };

  materialsCalculatorEvent({
    name: 'materialsCalculator',
    dimensions
  });

  const squareFootage = calcSquareFootage(dimensions);

  if (squareFootage) {
    const materials = calcMaterials(squareFootage);

    elPanelsShort.textContent = `${materials.sheet8}`;
    elPanelsMedium.textContent = `${materials.sheet10}`;
    elPanelsWide.textContent = `${materials.sheet12}`;
    elCompound.textContent = `${materials.compoundJoint}`;
    elCompoundLite.textContent = `${materials.compoundLite}`;
    elCompoundQuick.textContent = `${materials.compoundQuick}`;
    elTape.textContent = `${materials.tape}`;
    elScrews.textContent = `${materials.screws}`;
    elNails.textContent = `${materials.nails}`;

    elsCalculatorResults.forEach((el: HTMLElement) =>
      el.classList.remove('hidden', 'md:hidden')
    );
  }

  params.set('length', elLength.value);
  params.set('width', elWidth.value);
  params.set('height', elHeight.value);
  params.set('ceiling', `${elCeiling.checked}`);

  const queryString = params.toString();
  const url = `${window.location.origin}${window.location.pathname}?${queryString}`;
  window.history.pushState({}, '', url);
}

// function filterProduct(data:any, productType:string, defaultTitle:string, category:string = '') {
//   let products:any = data.filter((product:any) => product.productType.includes(productType));

//   if (category && category === 'Lite Weight') {
//     products = products.filter((product:any) => product.productCategories.includes('Lite Weight'));
//   }

//   return products.length ? products[0] : { title: defaultTitle, image: ''};
// }

// // updateProducts was written before default images & default products were added to the CMS, so this
// // code is no longer needed. It's purpose is to parse JSON data of all available products on the page
// // and replace HTML with the first filtered/related product.
// function updateProducts() {
//   const productData = JSON.parse(elProductData.dataset.calculatorData);
//   const drywall:Product = filterProduct(productData, 'Drywall Panels', 'Gypsum Boards');
//   const compoundJoint:Product = filterProduct(productData, 'Ready Mix', 'Joint Compound');
//   const compoundLite:Product = filterProduct(productData, 'Ready Mix', 'Quick Setting Compound', 'Lite Weight');
//   const compoundQuick:Product = filterProduct(productData, 'Setting Compounds', 'Quick Setting Compound');
//   const tape:Product = filterProduct(productData, 'Tape', 'Joint Tape');

//   if (drywall) {
//     elsDrywallImg.forEach((el:HTMLImageElement) => {
//       const img = el;

//       if (drywall.image) {
//         img.src = drywall.image;
//       }
//     });

//     elsDrywallTitle.forEach((el:HTMLSpanElement) => {
//       const span = el;
//       span.textContent = drywall.title;
//     });
//   }

//   if (compoundJoint) {
//     elCompoundTitle.textContent = compoundJoint.title;
//     if (compoundJoint.image) elCompoundImg.src = compoundJoint.image;
//   }

//   if (compoundLite) {
//     elCompoundLiteTitle.textContent = compoundLite.title;
//     if (compoundLite.image) elCompoundLiteImg.src = compoundLite.image;
//   }

//   if (compoundQuick) {
//     elCompoundQuickTitle.textContent = compoundQuick.title;
//     if (compoundQuick.image) elCompoundQuickImg.src = compoundQuick.image;
//   }

//   if (tape) {
//     elTapeTitle.textContent = tape.title;
//     if (tape.image) elTapeImg.src = tape.image;
//   }
// }

function autofillCalculator() {
  const length: string = params.get('length');
  const width: string = params.get('width');
  const height: string = params.get('height');
  const ceiling: string = params.get('ceiling');

  if (length) elLength.value = length;
  if (width) elWidth.value = width;
  if (height) elHeight.value = height;
  if (ceiling && ceiling === 'true') elCeiling.checked = true;

  if (length && width && height) calculate();
}

export default function Calculator() {
  if (elCalculatorForm) {
    // updateProducts();
    autofillCalculator();

    if (elDownload)
      elDownload.addEventListener('click', () => {
        materialsDownloadEvent({
          name: 'materialsDownload',
          cta: 'Download Installation Instructions',
        });
      });
    if (elPrint)
      elPrint.addEventListener('click', () => {
        materialsDownloadEvent({
          name: 'materialsDownload',
          cta: 'Print',
        });
        window.print();
      });
    if (elEmail)
      elEmail.addEventListener('click', () => {
        materialsDownloadEvent({
          name: 'materialsDownload',
          cta: 'Email List',
        });
        const queryString = encodeURIComponent(params.toString());
        const url = `${window.location.origin}${window.location.pathname}?${queryString}`;
        window.location.href = `
        mailto:?body=Here's a link to the materials needed: %0D%0A ${url}
      `;
      });

    elButton.addEventListener('click', calculate);
  }
}

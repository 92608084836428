function Select() {
    const selectToggles = document.querySelectorAll('[data-select-toggle]');
    const checkboxLabels = document.querySelectorAll('[data-checkbox]');
    const checkboxes = document.querySelectorAll('.input-checkbox');
    const scrollSections = document.querySelectorAll('[data-scroll-section]');

    selectToggles.forEach((toggle) => {
        toggle.addEventListener('click', () => {
            toggle.parentElement.classList.toggle('is-open');
        })
    });

    checkboxLabels.forEach((label:HTMLLabelElement) => {
        label.addEventListener('click', () => {
            const inputEl = <HTMLInputElement>document.getElementById(label.htmlFor);
            inputEl.checked = !inputEl.checked;
        })
    });

    if (window.location.pathname == '/project-profiles') {
        const footerDiv = <HTMLElement>document.querySelector('.Footer').firstChild;
        footerDiv.style.border = 'none';

        scrollSections.forEach((section:HTMLElement) => {
            section.style.display = "none";
        });
    }

    function resetCategories() {
        const labels = document.querySelectorAll('[data-checkbox]');
        labels.forEach((label:HTMLLabelElement) => {
            const inputEl = <HTMLInputElement>document.getElementById(label.htmlFor);
            inputEl.checked = false;
        });
    }

    if (document.querySelector('#pp-all-categories')) {
        const allCategories:HTMLInputElement = document.querySelector('#pp-all-categories');
        if (allCategories && !allCategories.checked) {
            document.querySelector('#allCategoriesLabel').addEventListener('click', resetCategories, { once: true });
        }
    }

    // After Sprig replaces component
    document.addEventListener('htmx:afterSwap', ({ detail }: any) => {
        if (window.location.pathname == '/project-profiles') {
            const sections = detail.target.querySelectorAll('[data-scroll-section]');
            sections.forEach((section:HTMLElement) => {
                section.style.display = "none";
            })
        }

        const allCategories:HTMLInputElement = document.querySelector('#allCategories');
        if (allCategories && !allCategories.checked) {
            document.querySelector('#allCategoriesLabel').addEventListener('click', resetCategories);
        }

        const labels = detail.target.querySelectorAll('[data-checkbox]');
        labels.forEach((label:HTMLLabelElement) => {
            label.addEventListener('click', () => {
                const inputEl = <HTMLInputElement>document.getElementById(label.htmlFor);
                inputEl.checked = !inputEl.checked;
            }, { once: true })
        })

        const toggle = detail.target.querySelector('[data-select-toggle]');
        if (toggle) {
            toggle.addEventListener('click', () => {
                toggle.parentElement.classList.toggle('is-open');
            })
        }
    });
}

export default Select;

function popUpWindow(url: string, w: number, h: number, title = '') {
  const wLeft = window.screenLeft ? window.screenLeft : window.screenX;
  const wTop = window.screenTop ? window.screenTop : window.screenY;

  const left = wLeft + window.innerWidth / 2 - w / 2;
  const top = wTop + window.innerHeight / 2 - h / 2;

  return window.open(
    url,
    title,
    `
    width=${w},
    height=${h},
    top=${top},
    left=${left},
    toolbar=no,
    location=no,
    directories=no,
    status=no,
    menubar=no,
    scrollbars=no,
    resizable=no,
    copyhistory=no
  `
  );
}

function openInNewTab(url: string) {
  if (!window.location.href.includes('kioskmode=true')) {
    var win = window.open(url, '_blank');
    win.focus();
  } else {
    window.top.postMessage('showErrorModal', '*');
  }
}

export default function onInit() {
  document.querySelectorAll('[data-social-share]').forEach((node) => {
    node.addEventListener('click', (e) => {
      e.preventDefault();
      const anchor = <HTMLAnchorElement>e.currentTarget;
      switch (anchor.dataset.socialShare) {
        case 'facebook':
          popUpWindow(
            `http://www.facebook.com/sharer/sharer.php?&sdk=joey&u=${anchor.href}&display=popup&ref=plugin`,
            675,
            350
          );
          break;
        case 'twitter':
          popUpWindow(
            `https://twitter.com/intent/tweet?&text=${anchor.href}`,
            550,
            443
          );
          break;
        case 'pinterest':
          popUpWindow(
            `http://pinterest.com/pin/create/button/?url=${anchor.href}`,
            760,
            680
          );
          break;
        case 'linkedin':
          popUpWindow(
            `https://www.linkedin.com/sharing/share-offsite/?url=${anchor.href}`,
            760,
            680
          );
          break;
        default:
          break;
      }
    });
  });

  document.querySelectorAll('[data-social-share-tab]').forEach((node) => {
    node.addEventListener('click', (e) => {
      e.preventDefault();
      const anchor = <HTMLAnchorElement>e.currentTarget;
      switch (anchor.dataset.socialShareTab) {
        case 'facebook':
          openInNewTab(
            `http://www.facebook.com/sharer/sharer.php?&sdk=joey&u=${anchor.href}&display=popup&ref=plugin`
          );
          break;
        case 'twitter':
          openInNewTab(
            `https://twitter.com/intent/tweet?&text=${anchor.href}`
          );
          break;
        case 'pinterest':
          openInNewTab(
            `http://pinterest.com/pin/create/button/?url=${anchor.href}`
          );
          break;
        case 'linkedin':
          openInNewTab(
            `https://www.linkedin.com/sharing/share-offsite/?url=${anchor.href}`
          );
          break;
        default:
          break;
      }
    });
  });
}

import { faqInteraction } from './ga4-events';

function accordion(node: HTMLElement) {
  const itemsButton = node.querySelectorAll('[data-accordion-btn]');

  function onClick(event: any): void {
    const nodeParent: any = event.currentTarget.parentNode;

    if (nodeParent.classList.contains('is-active')) {
      return nodeParent.classList.remove('is-active');
    }

    const activeItem = node.querySelector('[data-accordion-item].is-active');

    if (activeItem) {
      activeItem.classList.remove('is-active');
    }

    if (event.currentTarget.firstChild) {
      faqInteraction({
        name: 'faqInteraction',
        faqSelected: event.currentTarget.firstChild.textContent,
      });
    }

    return nodeParent.classList.add('is-active');
  }

  itemsButton.forEach((item: HTMLButtonElement) =>
    item.addEventListener('click', onClick)
  );
}

export default function onInit() {
  const accordions = document.querySelectorAll('[data-accordion]');

  if (!accordions.length) return;

  accordions.forEach((node: HTMLElement) => accordion(node));
}
